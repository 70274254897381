import { impersonateUser } from 'modules/user';
import ROUTES from 'pages/routes-enum';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GetTokensResponse, setAccessToken } from 'service/auth.service';
import { clearStorage, getItem } from 'service/storage.service';
import { setUserInfo } from 'service/user.service';
import { stringAsBool } from 'shared-types';
import { JwtUtils } from 'utils/jwt';
import { setOrRemoveLocalStorage } from 'utils/localStorage';

const forceLogin = {
  sessionActive: false,
};

enum SSOPrefs {
  HIDE_MENU = 'SSO_hideMenu',
  REDIRECT_TO = 'SSO_redirectTo',
}

type SetSSSoPrefParams = {
  hideMenu?: string;
  redirectTo?: string;
};

export const useLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getRouteToRedirect = (): string => {
    const route = localStorage.getItem(SSOPrefs.REDIRECT_TO);
    if (!route) return ROUTES.ROOT;
    return route;
  };
  const redirectToMain = () => history.replace(getRouteToRedirect());

  const setSsoPrefs = ({ hideMenu, redirectTo }: SetSSSoPrefParams) => {
    setOrRemoveLocalStorage(SSOPrefs.HIDE_MENU, hideMenu);
    setOrRemoveLocalStorage(SSOPrefs.REDIRECT_TO, redirectTo);
  };

  const loginUser = (loginData: GetTokensResponse, ssoPrefs: SetSSSoPrefParams) => {
    clearStorage();
    setAccessToken(loginData.access_token);
    setUserInfo(JSON.stringify(loginData.usuario));
    dispatch(
      impersonateUser({
        creditorId: loginData.usuario.id_credor_externo,
        officeId: loginData.usuario.id_escritorio,
      }),
    );
    setSsoPrefs(ssoPrefs);
    redirectToMain();
  };

  return { loginUser, redirectToMain, setSsoPrefs };
};

export const useSsoPref = () => {
  const ssoHideMenu = stringAsBool(localStorage.getItem(SSOPrefs.HIDE_MENU) ?? '');
  const redirectTo = localStorage.getItem(SSOPrefs.REDIRECT_TO);

  return { ssoHideMenu, redirectTo };
};

const loggedSessionSignupWithOtherUser = (
  currentSessionJwt: string,
  requestToken: string,
): boolean => {
  const loginRequestJwt = JwtUtils.readJwtPart(requestToken);
  const currentSessionJwtBody = JwtUtils.readJwtPart(currentSessionJwt);

  const currentLoggedExtSub = currentSessionJwtBody.extsub;
  const requestExtSub = loginRequestJwt.sub;
  return currentLoggedExtSub !== requestExtSub;
};

const isJwt = (maybeToken: string) => maybeToken.split('.').length === 3;

export const useLoggedState = (tokenFromRoute: string) => {
  const tok = getItem('access_token');
  if (!tok) return forceLogin;

  if (isJwt(tok)) {
    const jwtBody = JwtUtils.readJwtPart(tok);
    const expiry = Number(jwtBody.exp);

    if (Number.isNaN(expiry)) return forceLogin;

    const expired = expiry * 1000 < Date.now();
    if (expired) return forceLogin;

    if (isJwt(tokenFromRoute) && loggedSessionSignupWithOtherUser(tok, tokenFromRoute))
      return forceLogin;
  }

  return {
    sessionActive: true,
  };
};
