import { Theme } from '@material-ui/core/styles';

const GlobalSpreadSheetStyle = ({ theme }: { theme: Theme }) => `
    :root {
        --header_color: #fff !important;
        --header_color_highlighted: #fff !important;
        --header_background: ${
          theme.palette.type === 'light'
            ? theme.palette.primary.main
            : `${theme.splitcPalette.neutral[10]} !important`
        };
        --header_background_highlighted: ${
          theme.palette.type === 'light'
            ? theme.palette.primary.main
            : `${theme.splitcPalette.neutral[10]} !important`
        };
        --content_color: ${theme.palette.text.primary};
        --content_color_highlighted: #fff;
        --content_background: ${theme.splitcPalette.neutral[9]};
        --content_background_highlighted: rgba(0, 98, 173, 0.7);
        --menu_background: #fff;
        --menu_background_highlighted: #ebebeb;
        --menu_color: #222;
        --menu_color_highlighted: #222;
        --border_color: ${theme.splitcPalette.neutral[10]};
        --border_color_highlighted: #2184d0;
        --active_color: #eee;
        --cursor: rgb(33 133 208 / 30%);
    }

    .jss {
        background-color: transparent;
        border-radius: 8px;
    }

    .jss_toolbar {
        background-color: transparent !important;
    }

    .jss_scrollX::-webkit-scrollbar-track,
    .jss_scrollY::-webkit-scrollbar-track {
        background: ${theme.splitcPalette.neutral[10]};
        width: 6px;
        border-radius: 12px;
    }

    .jss_scrollX::-webkit-scrollbar-thumb,
    .jss_scrollY::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 12px;
    }

    .jss thead td:first-child {
        border-top-left-radius: 8px;
    }

    .jss thead td:last-child {
        border-top-right-radius: 8px;
    }

    .jss tbody tr:last-child td:first-child {
        border-bottom-left-radius: 8px;
    }

    .jss > tbody > tr > td.jss_cursor {
        border: 1px double var(--cursor);
        -webkit-transition: none;
        transition: none;
        -webkit-box-shadow: inset 0 -100px 0 rgb(33 133 208 / 15%);
        box-shadow: inset 0 -100px 0 rgb(33 133 208 / 15%);
    }

    .jss_container input,
    .jss_container select,
    .jss_page {
        color: #888;
        color: var(--content_color);
        background: #313131;
        background: var(--content_background);
        border: 1px solid #5f5f5f;
        border: 1px solid var(--border_color);
    }

    .jss > tbody > tr > td.jss_cursor a {
        color: ${theme.palette.primary.main};
    }

    @keyframes blink {
        30% {
            box-shadow: 0px 0px 0px 2px ${theme.palette.primary.main} inset;
        }
    }

    .jss_search_found {
        animation: blink 0.1s step-end 1 alternate;
        box-shadow: 0px 0px 0px 1px ${theme.palette.primary.main} inset;
    }

    .jss_input.jss_focus {
        text-align: center;
        background-color: var(--cursor);
    }

    .jdropdown-item {
        background-color: var(--content_background);
        color: var(--content_color);
    }

    .jss_border_main {
        background-color: var(--cursor);
    }

    .jss tbody tr td:first-child {
        border-top: 1px solid ${theme.splitcPalette.neutral[11]} !important;
    }

    .jss > tbody > tr > .jss_row {
        color: var(--header_color) !important;
    }

    /* Styles content color (besides header) */
    .jss > tbody > tr > td {
        color: var(--content_color) !important;
    }

    .jss_toolbar {
        position: relative;
        align-items: center;
        background-color: ${theme.splitcPalette.neutral[9]};
        margin: 0;
        padding: 0;
        border: none !important;
    }
    .jtoolbar .jtoolbar-item {
        position: relative;
        width: 24px;
        height: 22px;
        border-radius: 3px;
        text-align: center;
        top: 20px;
        margin: 0;
        padding: 0;
        border: none !important;
    }
    .jss_search {
        position: relative;
        left: 30px;
    }

    .jtoolbar .jtoolbar-selected {
        background-color: ${theme.splitcPalette.neutral[11]};
        position: relative;
        text-align: center;
    }
    .jtoolbar .material-icons {
        color: ${theme.palette.text.primary};
        font-size: 18px;
        width: 24px;
        height: 24px;
        display: block;
        line-height: 24px;
        text-align: center;
        &:hover {
            background-color: ${theme.palette.grey[700]};
        }
    }
    .jss_container.fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        padding: 4rem;
        width: 100%;
        height: 100%;
        max-width: 100% !important;
        max-height: 100% !important;
        background-color: ${theme.splitcPalette.neutral[11]};
        z-index: 31;
        display: flex;
        flex-direction: column;
    }

    .jss_container.fullscreen .jss_worksheet .jss_content {
        flex: 1;
        overflow: hidden;
        width: 100%;
        background-color: ${theme.splitcPalette.neutral[11]};
        max-width: initial !important;
        max-height: initial !important;
    }
`;

export default GlobalSpreadSheetStyle;
