import jwt_decode from 'jwt-decode';

export class JwtUtils {
  static readJwtPart(jwt: string): Record<string, unknown> {
    if (!jwt || jwt.split('.').length !== 3) {
      throw new Error(`Invalid argument {jwt}: value - ${jwt}`);
    }

    return jwt_decode(jwt);
  }
}
