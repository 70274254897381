import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const TeamReportIcon: React.FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { splitcPalette, palette } = useTheme();

  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 20h1a1 1 0 0 1 0 2H3a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v17h2v-1a1 1 0 1 1 2 0v1h2v-5a1 1 0 0 1 2 0v5h2v-2a1 1 0 0 1 2 0v2h4Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.95 6.823a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm2.503 2.912a3.075 3.075 0 1 0-4.995.015 4.818 4.818 0 0 0-2.383 4.199.975.975 0 1 0 1.95 0c0-1.688 1.34-2.926 2.925-2.926 1.635 0 3.075 1.284 3.075 2.926a.975.975 0 1 0 1.95 0c0-1.88-1.063-3.397-2.522-4.214Zm1.022-3.887c0-.539.436-.975.975-.975 1.738 0 3.075 1.336 3.075 3.075 0 .644-.17 1.237-.473 1.746 1.361.87 2.273 2.486 2.273 4.254a.975.975 0 0 1-1.95 0c0-1.532-1.064-2.735-2.2-2.941a.975.975 0 0 1-.327-1.796c.469-.28.727-.72.727-1.263 0-.662-.464-1.125-1.125-1.125a.975.975 0 0 1-.975-.975Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
