import {
  GetCreditorResponse,
  DeleteCreditorRequest,
  GetSingleCreditorResponse,
  CreditorGoalsResponse,
  PreferencesType,
} from 'shared-types';
import Remote from '.';

export type GetCreditors = {
  officeId: number | undefined;
  closureDate?: string;
  active?: boolean;
  withCommissions?: boolean;
};

const getAllCreditorsFromOffice = async ({
  officeId,
  closureDate,
  active,
  withCommissions,
}: GetCreditors): Promise<GetCreditorResponse[]> => {
  const { data } = await Remote.get(`/offices/${officeId}/creditors`, {
    params: {
      closure_date: closureDate,
      active,
      with_commissions: withCommissions,
    },
  });
  return data;
};

export const deleteCreditor = async (
  officeId: number,
  creditorToDelete: string,
  destinationCreditor?: string,
  update_preferences: boolean = false,
): Promise<void> => {
  const payload: DeleteCreditorRequest | null = destinationCreditor
    ? { merge_commissions_to: destinationCreditor }
    : null;
  const url = `/offices/${officeId}/creditors/${encodeURIComponent(creditorToDelete)}`;
  return Remote.delete(url, { data: payload, params: { update_preferences } });
};

export const handleDeleteCreditor = async ({
  creditorToDelete,
  officeId,
  update_preferences = false,
  destinationCreditor,
}: {
  officeId: number | undefined;
  creditorToDelete: string;
  destinationCreditor?: string;
  update_preferences?: boolean;
}) => {
  const payload: DeleteCreditorRequest | null = destinationCreditor
    ? { merge_commissions_to: destinationCreditor }
    : null;
  const url = `/offices/${officeId}/creditors/${encodeURIComponent(creditorToDelete)}`;
  return Remote.delete(url, { data: payload, params: { update_preferences } });
};

export const getCreditor = async (
  officeId: number,
  creditorId: string,
): Promise<GetSingleCreditorResponse> => {
  const url = `/offices/${officeId}/creditors/${encodeURIComponent(creditorId)}`;
  const { data } = await Remote.get<GetSingleCreditorResponse>(url);

  return data;
};

export const getCreditorGoals = async ({
  officeId,
  date,
  externalCreditorId,
}: {
  officeId: number;
  date: string;
  externalCreditorId: string;
}): Promise<CreditorGoalsResponse[]> => {
  const url = `/offices/${officeId}/preferences/${date}/${
    PreferencesType.GOALS
  }/creditors/${encodeURIComponent(externalCreditorId)}`;
  const { data } = await Remote.get<CreditorGoalsResponse[]>(url);
  return data;
};

export default getAllCreditorsFromOffice;
