import { useRef } from 'react';
import styled from 'styled-components';

type OfficeLogoVariant = 'mobile' | 'desktop';

interface OfficeLogoProps {
  src: string;
  alt?: string;
  variant?: OfficeLogoVariant;
}

export const Content = styled.img<{ $variant: OfficeLogoVariant }>`
  width: 100%;
  height: ${({ $variant }) => ($variant === 'desktop' ? '4rem' : '3rem')};
  object-fit: contain;
`;

export const OfficeLogo = ({ src, alt, variant = 'desktop' }: OfficeLogoProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  return (
    <Content
      $variant={variant}
      ref={imgRef}
      src={src}
      alt={alt}
      onError={() => {
        if (!imgRef.current) return;
        imgRef.current.src = `/transparent-logo.svg`;
      }}
    />
  );
};
