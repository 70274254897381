import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { iconBorderColor, iconFillColor } from '../../theme';
import { DefaultIconType } from './types';

export const HomeIcon: React.FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a1 1 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13Zm9-8.586 6 6V15l.001 5H6v-9.585l6-6Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path
        d="M12 17c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4Zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
