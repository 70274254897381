import { useMutation, useQuery } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'service/global/types';
import {
  BulkUpsertUserCheckResponse,
  BulkUpsertUserPermissionCheckResponse,
  CurrentUserResponse,
  SimpleUser,
  UserBulkUpsertQueryRequest,
  UserBulkUpsertRequest,
  UserBulkUpsertRequestFields,
  UserPermissionBulkUpsertQueryRequest,
  UserPermissionBulkUpsertRequest,
} from 'shared-types';
import { ONE_MINUTE_STALE_TIME } from 'constants/reactQuery';
import useSelector from 'modules/default-selector';
import { getAccessToken } from 'service/auth.service';
import Backend from '..';
import {
  makeGetUserListKey,
  makeGetUserKey,
  makeBulkUpsertUserQueryConfigKey,
  makeBulkUpsertUserPermissionQueryConfigKey,
} from './keys';
import { UpsertCustomers } from './types';

export function useGetUser(options: UseQueryOptions<CurrentUserResponse> = {}) {
  const userCached = useSelector(state => state.user);

  const getRemoteUser = async (): Promise<CurrentUserResponse> => {
    const { data } = await Backend.get<CurrentUserResponse>('/user');
    return data;
  };

  const shouldUseCache = userCached.credor?.id_credor && userCached;
  const hasAccessToken = !!getAccessToken();
  return useQuery(makeGetUserKey(), getRemoteUser, {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: ONE_MINUTE_STALE_TIME,
    initialData: () =>
      shouldUseCache
        ? {
            claims: userCached.claims!,
            credor: userCached.credor!,
            escritorio: userCached.escritorio!,
            teams: userCached.teams!,
          }
        : undefined,
    initialDataUpdatedAt: Date.now() - ONE_MINUTE_STALE_TIME,
    enabled: hasAccessToken,
    ...options,
  });
}

export function useUpsertCustomers(options?: UseMutationOptions<void>) {
  const upsertCustomers = async ({ officeId, payload }: UpsertCustomers): Promise<void> => {
    const { data } = await Backend.post<void>(`offices/${officeId}/customers`, payload);

    return data;
  };

  return useMutation(upsertCustomers, options);
}

export function useGetUserList(options: UseQueryOptions<SimpleUser[]> = {}) {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio as number);

  const getRemoteUserList = async (): Promise<SimpleUser[]> => {
    const { data } = await Backend.get<SimpleUser[]>(`/office/${officeId}/users`);
    return data;
  };

  return useQuery(makeGetUserListKey({ officeId }), getRemoteUserList, {
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });
}

export function useBulkUpsertUsers(options?: UseMutationOptions<void>) {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio)!;

  const bulkUpsertUser = async (payload: {
    fields_to_update: UserBulkUpsertRequestFields;
    payload: UserBulkUpsertRequest[];
  }) => {
    const { data } = await Backend.put(`/user/office/${officeId}`, payload);

    return data;
  };

  return useMutation(bulkUpsertUser, options);
}

export function useBulkUpsertUsersPermissions(options?: UseMutationOptions<void>) {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio)!;

  const bulkUpsertUserPermissions = async (payload: UserBulkUpsertRequest[]) => {
    const { data } = await Backend.put(`/user/office/${officeId}/permissions`, payload);

    return data;
  };

  return useMutation(bulkUpsertUserPermissions, options);
}

export function useBulkUpsertUserQuery() {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio)!;

  const bulkUpsertUserQuery = async (payload: UserBulkUpsertQueryRequest[]) => {
    const { data } = await Backend.post<UserBulkUpsertRequest[]>(
      `/user/office/${officeId}/query`,
      payload,
    );

    return data;
  };

  return useMutation(bulkUpsertUserQuery);
}

export function useBulkUpsertUserQueryConfig(
  options: UseQueryOptions<UserBulkUpsertQueryRequest[]> = {},
) {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio)!;

  const bulkUpsertUserQuery = async () => {
    const { data } = await Backend.get<UserBulkUpsertQueryRequest[]>(
      `/user/office/${officeId}/query`,
    );

    return data;
  };

  return useQuery(makeBulkUpsertUserQueryConfigKey({ officeId }), bulkUpsertUserQuery, options);
}

export function useBulkUpsertUserPermissionsQuery() {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio)!;

  const bulkUpsertUserQuery = async (payload: UserPermissionBulkUpsertQueryRequest[]) => {
    const { data } = await Backend.post<UserBulkUpsertQueryRequest[]>(
      `/user/office/${officeId}/permissions/query`,
      payload,
    );

    return data;
  };

  return useMutation(bulkUpsertUserQuery);
}

export function useBulkUpsertUserPermissionsQueryConfig(
  options: UseQueryOptions<UserPermissionBulkUpsertQueryRequest[]> = {},
) {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio)!;

  const bulkUpsertUserQuery = async () => {
    const { data } = await Backend.get<UserPermissionBulkUpsertQueryRequest[]>(
      `/user/office/${officeId}/permissions/query`,
    );

    return data;
  };

  return useQuery(
    makeBulkUpsertUserPermissionQueryConfigKey({ officeId }),
    bulkUpsertUserQuery,
    options,
  );
}

export function useBulkUpsertUserCheck() {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio)!;

  const bulkUpsertUserCheck = async (payload: {
    fields_to_update: UserBulkUpsertRequestFields;
    payload: UserBulkUpsertRequest[];
  }) => {
    const { data } = await Backend.post<BulkUpsertUserCheckResponse[]>(
      `/user/office/${officeId}/check`,
      payload,
    );

    return data;
  };

  return useMutation(bulkUpsertUserCheck);
}

export function useBulkUpsertUserPermissionCheck() {
  const officeId = useSelector(state => state.user.escritorio?.id_escritorio)!;

  const bulkUpsertUserPermissionCheck = async (payload: UserPermissionBulkUpsertRequest[]) => {
    const { data } = await Backend.post<BulkUpsertUserPermissionCheckResponse[]>(
      `/user/office/${officeId}/permissions/check`,
      payload,
    );

    return data;
  };

  return useMutation(bulkUpsertUserPermissionCheck);
}
