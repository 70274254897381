import { createAction, createReducer } from 'redux-act';
import {
  UsersClaims,
  CurrentUserResponse,
  SimpleUser,
  GetCreditorResponse,
  UserPreferences,
} from 'shared-types';
import { setUser as errorTraceUserData, handleDefaultErrors } from 'utils/error';
import hasPermission from 'utils/permission.utils';
import { removeItem, storeItem } from 'service/storage.service';
import { getRemoteUserList } from '../../service/user.service';
import getAllCreditorsFromOffice, { deleteCreditor } from '../../service/creditor.service';
import { DefaultThunk } from '../typings';
import { invalidateCreditorQueries, invalidatePreferencesQueries } from '../common';
import { showAlertModal } from '../ui';
import { HasSeenFeature, ImpersonatedUserType, TabPreference, UserPrefs, UserState } from './types';
import { IMPERSONATION_ITEM_KEY, ImpersonationUserPayload } from './impersonation';

const loading = createAction<boolean>('commission/USER/LOADING');
export const fetched = createAction<CurrentUserResponse>('commission/USER/FETCHED');
export const toggleHideAmount = createAction<boolean>('commission/USER/AMOUNT_HIDDEN_TOGGLED');
export const toggleCollapsedMenu = createAction<boolean>('commission/USER/COLLAPSE_MENU_TOGGLED');
export const dateSelected = createAction<string>('commission/USER/DATE_SELECTED');
export const tabSelected = createAction<{
  plan?: number;
  tab_id?: number;
  page: TabPreference['page'];
}>('tabs/USER/TAB_SELECTED');
export const hasSeenFeature = createAction<HasSeenFeature>('commission/USER/HAS_SEEN_FEATURE');

export const preferencesLoaded = createAction<Partial<UserPreferences>>(
  'commission/USER/PREFERENCES_LOADED',
);

export const updateStatementPlanInfo = createAction<{
  plan?: number;
  selected_statement?: number | null;
  selected_dashboard_user?: string | null;
}>('commission/USER/STATEMENT_INFO');

const setOfficeCreditorList = createAction<GetCreditorResponse[]>('comission/USER/SET_COWORKERS');
export const impersonateUser = createAction<ImpersonatedUserType>('comission/USER/IMPERSONATE');
export const setUserList = createAction<SimpleUser[]>('comission/USER/SET_LIST');
export const impersonationUser = createAction<ImpersonationUserPayload>(
  'comission/USER/IMPERSONATION_USER',
);
export const exitImpersonation = createAction('comission/USER/EXIT_IMPERSONATION');

const defaultState: UserState = {
  loading: true,
  name: null,
  preferences: {
    hideAmounts: true,
    menuOpened: true,
    selectedDate: '',
    hideLogo: false,
    hideLogout: false,
    plans: {},
    outside_plan: {},
    has_seen_feature: {},
  },
};

const reducer = createReducer<UserState>({}, defaultState);

reducer.on(
  loading,
  (state, payload): UserState => ({
    ...state,
    loading: payload,
  }),
);

reducer.on(
  fetched,
  (state, payload: CurrentUserResponse): UserState => ({
    ...state,
    ...payload,
    ...(state.impersonatedUser || {
      creditorId: payload.credor.id_credor_externo,
      officeId: payload.escritorio.id_escritorio,
    }),
  }),
);

reducer.on(
  setUserList,
  (state, payload: SimpleUser[]): UserState => ({
    ...state,
    userList: payload,
  }),
);

reducer.on(
  setOfficeCreditorList,
  (state, payload: GetCreditorResponse[]): UserState => ({
    ...state,
    creditorsFromOffice: payload,
  }),
);

reducer.on(updateStatementPlanInfo, (state, payload) => {
  if (!payload.plan) return state;

  const { plans } = state.preferences;
  const planTabPrefs = plans?.[payload.plan] ?? {
    [payload.plan]: {
      page: 'statement',
    },
  };

  const invalidConfig = typeof planTabPrefs === 'number';
  if (invalidConfig) {
    return {
      ...state,
      preferences: defaultState.preferences,
    };
  }

  if (payload.selected_statement === null) {
    planTabPrefs.selected_statement = undefined;
  } else if (payload.selected_statement) {
    planTabPrefs.selected_statement = payload.selected_statement;
  }

  if (payload.selected_dashboard_user === null) {
    planTabPrefs.selected_dashboard_user = undefined;
  } else if (payload.selected_dashboard_user) {
    planTabPrefs.selected_dashboard_user = payload.selected_dashboard_user;
  }

  return {
    ...state,
    preferences: {
      ...state.preferences,
      plans: { ...(plans ?? {}), [payload.plan]: planTabPrefs },
    },
  };
});

reducer.on(
  impersonationUser,
  (state, payload: ImpersonationUserPayload): UserState => {
    storeItem(IMPERSONATION_ITEM_KEY, JSON.stringify(payload));
    return {
      ...state,
      impersonationUser: {
        externalCreditorId: payload.externalCreditorId,
        name: payload.name,
      },
      impersonatedUser: {
        creditorId: payload.externalCreditorId,
        officeId: state.escritorio?.id_escritorio ?? null,
      },
    };
  },
);

reducer.on(
  exitImpersonation,
  (state): UserState => {
    removeItem(IMPERSONATION_ITEM_KEY);
    return {
      ...state,
      impersonationUser: null,
      credor: undefined,
      impersonatedUser: undefined,
    };
  },
);

reducer.on(
  impersonateUser,
  (state, payload): UserState => ({
    ...state,
    impersonatedUser: payload,
  }),
);

reducer.on(
  toggleHideAmount,
  (state, payload): UserState => {
    const prefs: UserPrefs = { ...state.preferences, hideAmounts: payload };
    return {
      ...state,
      preferences: prefs,
    };
  },
);

reducer.on(
  toggleCollapsedMenu,
  (state, payload): UserState => {
    const prefs: UserPrefs = { ...state.preferences, menuOpened: payload };
    return {
      ...state,
      preferences: prefs,
    };
  },
);

reducer.on(
  tabSelected,
  (state, payload): UserState => {
    if (!payload.plan) {
      const outsidePlanTabPrefs = state.preferences.outside_plan || {};
      const invalidConfig = typeof outsidePlanTabPrefs === 'number';
      if (invalidConfig) {
        return {
          ...state,
          preferences: defaultState.preferences,
        };
      }

      const prefsCopy: UserPrefs = {
        ...state.preferences,
        outside_plan: outsidePlanTabPrefs,
      };

      if (payload.page !== 'statement') outsidePlanTabPrefs.page = payload.page;

      if (payload.page === 'calc') {
        outsidePlanTabPrefs.calc_tab = payload.tab_id;
      }

      if (payload.page === 'sources') {
        outsidePlanTabPrefs.source_tab = payload.tab_id;
      }

      return { ...state, preferences: prefsCopy };
    }

    const planPrefs = state.preferences?.plans?.[payload.plan] ?? {};

    const invalidConfig = typeof planPrefs === 'number';
    if (invalidConfig) {
      return {
        ...state,
        preferences: defaultState.preferences,
      };
    }

    const prefsCopy: UserPrefs = {
      ...state.preferences,
      plans: { ...(state.preferences?.plans ?? {}), [payload.plan]: planPrefs },
    };
    planPrefs.page = payload.page;

    if (payload.page === 'calc') {
      planPrefs.calc_tab = payload.tab_id;
    }

    if (payload.page === 'sources') {
      planPrefs.source_tab = payload.tab_id;
    }

    return {
      ...state,
      preferences: prefsCopy,
    };
  },
);

reducer.on(
  dateSelected,
  (state, payload): UserState => {
    const prefs: UserPrefs = { ...state.preferences, selectedDate: payload };
    return {
      ...state,
      preferences: prefs,
    };
  },
);

reducer.on(
  preferencesLoaded,
  (state, payload): UserState => {
    const prefs = { ...defaultState.preferences, ...payload };
    return {
      ...state,
      preferences: prefs,
    };
  },
);

reducer.on(
  hasSeenFeature,
  (state, payload): UserState => {
    const prefs: UserPrefs = {
      ...state.preferences,
      has_seen_feature: {
        ...state.preferences.has_seen_feature,
        ...payload,
      },
    };
    return {
      ...state,
      preferences: prefs,
    };
  },
);

export const refreshCreditorList = ({
  active,
  closureDate,
}: { closureDate?: string; active?: boolean } = {}) => async (dispatch, getState) => {
  // TODO: check why { user } isnt typed
  const { user } = getState();

  if (
    !hasPermission(user?.claims, [
      UsersClaims.OFFICE_CREDITORS_VIEW,
      UsersClaims.TEAM_CREDITORS_VIEW,
    ])
  )
    return;

  try {
    if (!user.escritorio?.id_escritorio)
      throw new Error('Tried to refreshCreditorList() without state.user.escritorio.id_escritorio');

    if (!user.credor) throw new Error('Tried to refreshCreditorList() without state.user.credor');

    const creditors = await getAllCreditorsFromOffice({
      officeId: user.escritorio.id_escritorio,
      closureDate,
      active,
    });

    dispatch(setOfficeCreditorList(creditors));

    const listHasUser = (creditorId?: string) =>
      creditors.some(creditor => creditor.id_credor_externo === creditorId);

    const userFrom = (creditorId: string): ImpersonatedUserType => ({
      creditorId,
      officeId: user.escritorio.id_escritorio,
    });

    const loggedUser = userFrom(user.credor.id_credor_externo);
    const firstUserOnList =
      creditors.length !== 0 ? userFrom(creditors[0].id_credor_externo) : null;

    const listHasLoggedUser = listHasUser(user?.credor?.id_credor_externo);
    const listHasImpersonatedUser = listHasUser(user?.impersonatedUser?.creditorId);

    let userToImpersonate: ImpersonatedUserType | null = null;
    if (listHasImpersonatedUser) userToImpersonate = user.impersonatedUser;
    else if (listHasLoggedUser) userToImpersonate = loggedUser;
    else userToImpersonate = firstUserOnList ?? loggedUser;

    if (!userToImpersonate) throw new Error('Cant impersonate any user');

    dispatch(impersonateUser(userToImpersonate));
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const getRemoteList = () => async (dispatch, getState) => {
  try {
    dispatch(loading(true));
    const { user } = getState();
    const remoteUsers = await getRemoteUserList(user.escritorio.id_escritorio);
    dispatch(setUserList(remoteUsers));
  } catch (error) {
    handleDefaultErrors(error);
  } finally {
    dispatch(loading(false));
  }
};

export const setupUserTracking = (user: CurrentUserResponse | UserState) => {
  errorTraceUserData({
    email: user?.credor?.email,
    id: user?.credor?.id_credor,
    name: user?.credor?.nome_credor,
    ...user,
  });
};
export const removeCreditor = (
  creditorId: string,
  destinationCreditor?: string,
  updatePreferences: boolean = false,
  onDeleteSuccess: () => void = () => {},
): DefaultThunk => async (dispatch, getState) => {
  try {
    const { user } = getState();
    if (!user?.escritorio?.id_escritorio)
      throw new Error('Cant delete creditor without user.escritorio.id_escritorio');
    const officeId = user.escritorio.id_escritorio;
    dispatch(loading(true));
    await deleteCreditor(officeId, creditorId, destinationCreditor, updatePreferences);
    onDeleteSuccess();
    invalidateCreditorQueries();
    invalidatePreferencesQueries();
    const impersonatedIsDeleted = user.impersonatedUser?.creditorId === creditorId;
    if (impersonatedIsDeleted) {
      const loggedUser: ImpersonatedUserType = {
        creditorId: user?.credor?.id_credor_externo ?? '',
        officeId: user?.escritorio.id_escritorio,
      };
      dispatch(impersonateUser(loggedUser));
    }

    if (updatePreferences)
      dispatch(
        showAlertModal({
          heading: 'Regras atualizadas com sucesso! ',
          body: 'Lembre-se de aplicar a troca de campos nos fechamentos desbloqueados!',
        }),
      );

    dispatch(getRemoteList());
  } catch (error) {
    handleDefaultErrors(error);
  } finally {
    dispatch(loading(false));
  }
};

export * from './types';
export default reducer;
