import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk, { ThunkMiddleware } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RootState } from 'modules/typings';
import reducers from '../modules';

interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
}
declare const window: ExtendedWindow;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
  : compose;

const middlewares = [reduxThunk as ThunkMiddleware<RootState>];

const persistConfig = {
  key: 'root',
  storage,
};
const persistenceRedux = persistReducer<RootState & any, any>(persistConfig, reducers);

export const storeCreator = preloadedState =>
  createStore<RootState, any, any, any>(
    persistenceRedux,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

const store = storeCreator({});

export const { dispatch } = store;

export const persistor = persistStore(store);

export default store;
