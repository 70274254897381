const MAX_PARALLEL_REQUESTS = 5;

type PartitionPagination = [number, number][];
type PaginationPartitionsRequest<T> = (
  from?: number,
  to?: number,
) => Promise<{
  results: T[];
  pagination?: PartitionPagination;
}>;

export const fetchingWithPaginationPartitions = async <T>(
  request: PaginationPartitionsRequest<T>,
  maxParallelRequests = MAX_PARALLEL_REQUESTS,
): Promise<T[]> => {
  const { results: firstResults, pagination } = await request();
  if (!pagination?.length) {
    return firstResults;
  }

  const results: T[] = firstResults;

  const pagesGroupedByMax = pagination.reduce((acc, range) => {
    const last = acc[acc.length - 1] || [];
    if (last.length < maxParallelRequests) {
      last.push(range);

      if (acc.length === 0) acc.push(last);
      else acc[acc.length - 1] = last;

      return acc;
    }

    acc.push([range]);
    return acc;
  }, [] as PartitionPagination[]);

  for await (const pagesToProcess of pagesGroupedByMax) {
    if (!pagesToProcess) continue;
    const promises: ReturnType<PaginationPartitionsRequest<T>>[] = [];
    pagesToProcess.forEach(([from, to]) => promises.push(request(from, to)));

    const resultPromises = await Promise.all(promises);
    resultPromises.map(item => {
      return results.push(...item.results);
    });
  }

  return results;
};
