import { initializeApp } from 'firebase/app';
import { getAnalytics, Analytics } from 'firebase/analytics';
import FirebaseConfiguration from 'configuration/firebase.configuration';

export const createAnalytics = (): Analytics => {
  const firebaseConfig = new FirebaseConfiguration();
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return analytics;
};
