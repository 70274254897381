import React, { createContext } from 'react';
import { Analytics } from 'firebase/analytics';

type AnalyticsProviderProps = {
  analytics?: Analytics;
};

export const AnaliticsContext = createContext<AnalyticsProviderProps>({});

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children, analytics }) => {
  return (
    <AnaliticsContext.Provider
      value={{
        analytics,
      }}
    >
      {children}
    </AnaliticsContext.Provider>
  );
};
