import { createAction, createReducer } from 'redux-act';

export interface ManualTabDraftState {
  isDraft: boolean;
  draftModalOpen: boolean;
  isManualTabOpen: boolean;
}

const defaultState: ManualTabDraftState = {
  isDraft: false,
  draftModalOpen: false,
  isManualTabOpen: false,
};

const reducer = createReducer({}, defaultState);

export const setManualTabDraft = createAction<boolean>('manualtab/IS_MANUAL_TAB_DRAFT');

export const setManualDraftModalOpen = createAction<boolean>(
  'manualtab/IS_MANUAL_TAB_DRAFT_MODAL_OPEN',
);

export const setManualTabOpen = createAction<boolean>('manualtab/MANUAL_TAB_OPEN');

reducer.on(setManualTabOpen, (state, payload) => ({
  ...state,
  isManualTabOpen: payload,
}));

reducer.on(setManualTabDraft, (state, payload) => ({
  ...state,
  isDraft: payload,
}));

reducer.on(setManualDraftModalOpen, (state, payload) => ({
  ...state,
  draftModalOpen: payload,
}));

export default reducer;
