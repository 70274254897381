import { SplitcPalete } from './types';

const sharedColors = {
  infraRed: {
    1: '#ff2323',
    2: '#fff0f0',
    3: '#ffdddd',
    4: '#ffc0c0',
    5: '#ff9494',
    6: '#ff5757',
    7: '#ff2323',
    8: '#ff0000',
    9: '#d70000',
    10: '#b10303',
    11: '#920a0a',
    12: '#500000',
  },
  leaf: {
    1: '#81D59D',
  },
  lexicalHighlight: {
    1: '#CC6805',
    2: '#0065D1',
    3: '#23AFA3',
    4: '#AB5FF6',
    5: '#C2B90F',
  },
  success: {
    1: '#40BF6A',
    2: '#f2fbf5',
    3: '#e1f7e7',
    4: '#c4eed1',
    5: '#95e0ad',
    6: '#60c882',
    7: '#40bf6a',
    8: '#2b8e4b',
    9: '#25703e',
    10: '#225935',
    11: '#1d4a2d',
    12: '#0b2816',
  },
  orange: {
    1: '#fffbea',
    2: '#fff2c5',
    3: '#ffe585',
    4: '#ffd146',
    5: '#ffbc1b',
    6: '#ff9800',
    7: '#e27100',
    8: '#bb4c02',
    9: '#983a08',
    10: '#7c300b',
    11: '#481700',
  },
};

export const darkColors: SplitcPalete = {
  ...sharedColors,
  palete: {
    primary: {
      main: '#F59E18',
    },
    secondary: {
      main: '#F44336',
    },
    type: 'dark',
  },
  background: {
    1: '#0D0D0D',
  },
  neutral: {
    1: '#FDFDFD',
    2: '#F7F7F7',
    3: '#E5E5E5',
    4: '#CCCCCC',
    5: '#ABABAB',
    6: '#808080',
    7: '#696969',
    8: '#525252',
    9: '#3B3B3B',
    10: '#242424',
    11: '#151515',
  },
  yolk: {
    1: '#FFFEFA',
    2: '#FEFDF1',
    3: '#FBF9D0',
    4: '#F7F3A1',
    5: '#F3EB63',
    6: '#EBE013',
    7: '#C2B90F',
    8: '#97900C',
    9: '#6D6809',
    10: '#423F05',
    11: '#181702',
  },
  twilight: {
    1: '#F9FEFD',
    2: '#F2FCFC',
    3: '#D4F7F4',
    4: '#AAEEE9',
    5: '#72E3DA',
    6: '#2AD5C6',
    7: '#23AFA3',
    8: '#1B887F',
    9: '#13625C',
    10: '#0C3C37',
    11: '#041413',
  },
  ultraviolet: {
    1: '#FDFAFF',
    2: '#F7F0FE',
    3: '#E6CFFC',
    4: '#CC9EFA',
    5: '#AB5FF6',
    6: '#680AC6',
    7: '#680AC6',
    8: '#52089B',
    9: '#3B066F',
    10: '#240344',
    11: '#0D0118',
  },
  goalsChip: ['#F3EB63', '#72E3DA', '#AB5FF6'],
};

export const lightColors: SplitcPalete = {
  ...sharedColors,
  palete: {
    primary: {
      main: '#D28817',
    },
    secondary: {
      main: '#000',
    },
    type: 'light',
  },
  background: {
    1: '#FFF',
  },
  neutral: {
    1: '#151515',
    2: '#242424',
    3: '#3B3B3B',
    4: '#525252',
    5: '#696969',
    6: '#808080',
    7: '#ABABAB',
    8: '#CCCCCC',
    9: '#E5E5E5',
    10: '#F7F7F7',
    11: '#FDFDFD',
  },
  yolk: {
    1: '#181702',
    2: '#423F05',
    3: '#6D6809',
    4: '#97900C',
    5: '#C2B90F',
    6: '#EBE013',
    7: '#F3EB63',
    8: '#F7F3A1',
    9: '#FBF9D0',
    10: '#FEFDF1',
    11: '#FFFEFA',
  },
  twilight: {
    1: '#041413',
    2: '#0C3C37',
    3: '#13625C',
    4: '#1B887F',
    5: '#23AFA3',
    6: '#2AD5C6',
    7: '#72E3DA',
    8: '#AAEEE9',
    9: '#D4F7F4',
    10: '#F2FCFC',
    11: '#F9FEFD',
  },
  ultraviolet: {
    1: '#0D0118',
    2: '#240344',
    3: '#3B066F',
    4: '#52089B',
    5: '#680AC6',
    6: '#680AC6',
    7: '#AB5FF6',
    8: '#CC9EFA',
    9: '#E6CFFC',
    10: '#F7F0FE',
    11: '#FDFAFF',
  },
  goalsChip: ['#C2B90F', '#23AFA3', '#680AC6'],
};
