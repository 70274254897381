import { QueryClient, useQuery, useMutation, QueryKey } from 'react-query';
import { errorStore } from 'stores/errorStore';
import { isAxiosError } from 'utils/error';
import {
  getCreditorPaycheck,
  getCommissionDivisions,
  putRecurrentRevenue,
} from './commission.service';

import { updateClosureState, getClosure } from './closure.service';
import getAllCreditorsFromOffice, { GetCreditors } from './creditor.service';
import { getPayments } from './payment.service';
import { getCreditorIframeUrl } from './dashboard.service';

export enum QueryPrefixes {
  CREDITOR_GOALS = 'creditor-goals',
  CREDITOR_COMMISSIONS = 'creditor-commissions',
  GET_OWN_COMMISSIONS = 'get-owned-commissions',
  CREDITOR_CLOSURES = 'creditor-closures',
  CREDITOR_PAYCHECK = 'creditor-paycheck',
  COMMISSION_DIVISIONS = 'creditor-commission-divisions',
  CLOSURE_INFO = 'closure-info',
  CLOSURE_OPEN_CLOSE = 'closure-open-close',
  GET_PREFERENCES = 'get-preferences',
  GET_PAYMENTS = 'get-payments',
  GET_COMPANIES = 'get-companies',
  GET_COMPANY_BALANCE = 'get-company-balance',
  GET_ALL_CREDITORS_FROM_OFFICE = 'get-all_creditors-from-office',
  DASHBOARDS = 'dashboards',
  CUSTOM_REPORTS = 'custom-reports',
  GOAL_PREFERENCES_QUERY_KEY = 'goal-preferences',
  CLOSURES_MONTHS = 'closure_months',
  CREDITOR_EARNED_AMOUNT = 'creditor-earned-amount',
  JOB_STATUS = 'job-status',
  GET_USER_LIST = 'get-user-list',
  GET_USER = 'get-user',
  HAS_COMMISIONS = 'has-commisions',
  GET_TEAMS = 'get-teams',
  CLOSURE_VARIABLES = 'closure-variables',
  USER = 'user',
  GET_MANAGED_PROFILE = 'get-managed-profile',
  GET_MANAGED_PROFILES = 'get-managed-profiles',
}

const OPTIONS_REACT_QUERY = {
  refetchOnWindowFocus: false,
  staleTime: 60000,
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (count, err) => {
        if (isAxiosError(err) && err.response?.status)
          return !(err.response.status >= 400 && err.response.status < 500);

        return count < 3;
      },
    },
    mutations: {
      onError: errorStore.errorSink,
    },
  },
});
/**
 * invalidates a array of queries, use this instead of invalidateQueries of react-query when you need to invalidate multiple queries
 * @param queries - Array of queries to be invalidated
 *
 */
export const invalidateQueries = (queries: string[] | QueryKey[]) => {
  queries.forEach(query => queryClient.invalidateQueries(query));
};

export const useGetCreditorPaycheck = ({
  officeId,
  externalCreditorId,
  closureDate,
}: {
  officeId: number | null | undefined;
  externalCreditorId: string | null | undefined;
  closureDate: string | null | undefined;
}) =>
  useQuery(
    [QueryPrefixes.CREDITOR_PAYCHECK, officeId, externalCreditorId, closureDate],
    () =>
      getCreditorPaycheck({
        officeId: Number(officeId),
        closureDate: String(closureDate),
        externalCreditorId: String(externalCreditorId),
      }),
    { ...OPTIONS_REACT_QUERY, enabled: !!officeId && !!externalCreditorId && !!closureDate },
  );

export const useGetCommissionDivisions = ({
  officeId,
  commissionId,
  impersonatedUser,
}: {
  officeId: number;
  commissionId: number;
  impersonatedUser: string;
}) =>
  useQuery(
    [QueryPrefixes.COMMISSION_DIVISIONS, officeId, commissionId, impersonatedUser],
    () =>
      getCommissionDivisions({
        officeId,
        commissionId,
        impersonatedUser,
      }),
    OPTIONS_REACT_QUERY,
  );

export const useGetClosure = ({
  officeId,
  closureDate,
}: {
  officeId: number;
  closureDate: string;
}) =>
  useQuery(
    [QueryPrefixes.CLOSURE_INFO, officeId, closureDate],
    () =>
      getClosure({
        officeId,
        closureDate,
      }),
    { ...OPTIONS_REACT_QUERY, staleTime: 100000 },
  );

export const useClosureOpenClose = () => {
  const updateCloseOpen = ({
    released,
    officeId,
    selectedDate,
    notifyUsers = false,
  }: {
    released: boolean;
    officeId: number;
    selectedDate: string;
    notifyUsers?: boolean;
  }) =>
    updateClosureState({
      change: { liberado: released, notifyUsers },
      officeId,
      date: selectedDate,
    });
  return useMutation(updateCloseOpen, {
    onSuccess: () => invalidateQueries([QueryPrefixes.CLOSURE_INFO]),
  });
};

export const useGetPayments = ({
  officeId,
  closureDate,
}: {
  officeId: number;
  closureDate: string;
}) =>
  useQuery(
    [QueryPrefixes.GET_PAYMENTS, officeId, closureDate],
    () => getPayments(officeId, closureDate),
    OPTIONS_REACT_QUERY,
  );

export const useRecurrentMutation = () => useMutation(putRecurrentRevenue);

export const useGetAllCreditorsFromOffice = ({
  officeId,
  closureDate,
  active,
  withCommissions,
}: GetCreditors) =>
  useQuery(
    [QueryPrefixes.GET_ALL_CREDITORS_FROM_OFFICE, officeId, closureDate, active, withCommissions],
    () => getAllCreditorsFromOffice({ officeId, closureDate, active, withCommissions }),
    OPTIONS_REACT_QUERY,
  );

export const useGetDashboardCreditors = () =>
  useQuery([QueryPrefixes.DASHBOARDS], () => getCreditorIframeUrl(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

export const THREE_MINUTES_STALE_TIME = 1000 * 60 * 3;
