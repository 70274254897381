import { Box, Button, Typography } from '@material-ui/core';
import Lottie from 'react-lottie';
import { defaultOpts } from '.';
import errorAnimation from './error.json';
import * as S from './styles';

interface ErrorProps {
  allowReload?: boolean;
}

const Error = ({ allowReload = true }: ErrorProps) => {
  return (
    <S.Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h4" gutterBottom>
        Opa! Algo deu errado!
      </Typography>

      <Lottie
        {...defaultOpts}
        options={{ ...defaultOpts.options, animationData: errorAnimation }}
      />

      {allowReload && (
        <Box marginTop={2}>
          <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
            Recarregar Página
          </Button>
        </Box>
      )}

      <Box marginTop={2}>
        <S.VersionContainer>
          <Typography color="textPrimary" variant="body1">
            {import.meta.env?.VITE_COMMIT && `Versão: ${import.meta.env.VITE_COMMIT}`}
          </Typography>
        </S.VersionContainer>
      </Box>
    </S.Container>
  );
};

export default Error;
