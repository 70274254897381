import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import { LateralPadding } from 'components/styles';
import { Grid, Paper, useTheme } from '@material-ui/core';

const Loading = styled(ContentLoader)`
  display: block;
  display: flex;
`;

type Props = {
  width?: string;
  height?: string;
  lineCount?: number;
  lineSpacing?: number;
  lineHeight?: number;
  paper?: boolean;
  padding?: number;
  speed?: number;
  className?: string;
  loadingStyle?: {
    backgroundColor?: string;
    foregroundColor?: string;
  };
  containerClassName?: string;
};

export const Skeleton: React.FC<Props> = ({
  width = '100px',
  height = '560px',
  lineCount = 5,
  lineSpacing = 15,
  lineHeight = 8,
  paper = true,
  padding,
  speed = 2,
  className,
  loadingStyle,
  containerClassName,
}) => {
  const theme = useTheme();
  const children: JSX.Element[] = [
    <rect key="line-loader-0" x="0" y="2" rx="0" ry="0" width="100%" height={String(lineHeight)} />,
  ];
  for (let i = 1; i < lineCount; i += 1) {
    const initialY = 2;
    const y = String(lineSpacing * i + initialY);
    children.push(
      <rect
        key={`line-loader-${i}`}
        x="0"
        y={y}
        rx="0"
        ry="0"
        width="100%"
        height={String(lineHeight)}
      />,
    );
  }

  const Content = (
    <LateralPadding $amount={padding} className={className} data-testid="skeleton">
      <Grid container justifyContent="center" alignItems="center">
        <Loading
          speed={speed}
          width={width}
          height={height}
          backgroundColor={loadingStyle?.backgroundColor ?? theme.palette.background.default}
          foregroundColor={loadingStyle?.foregroundColor ?? theme.palette.background.paper}
        >
          {children}
        </Loading>
      </Grid>
    </LateralPadding>
  );

  if (paper)
    return (
      <Paper className={containerClassName} style={{ width }}>
        {Content}
      </Paper>
    );
  return (
    <Grid container justifyContent="center" alignItems="center" className={containerClassName}>
      {Content}
    </Grid>
  );
};
