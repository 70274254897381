import { QueryKey } from 'react-query';
import { QueryPrefixes } from 'service/query.client';

export const USER_QUERY_PREFIX = {
  USER: QueryPrefixes.USER,
  GET_USER_LIST: 'get-user-list',
  GET_UPSERT_USER_QUERY_CONFIG: 'get-upsert-user-query-config',
  GET_UPSERT_USER_PERMISSION_QUERY_CONFIG: 'get-upsert-user-permission-query-config',
};

export function makeGetUserKey(): QueryKey {
  return [USER_QUERY_PREFIX.USER];
}

export function makeGetUserListKey({ officeId }: { officeId: number }): QueryKey {
  return [USER_QUERY_PREFIX.GET_USER_LIST, officeId];
}

export function makeBulkUpsertUserQueryConfigKey({ officeId }: { officeId: number }): QueryKey {
  return [USER_QUERY_PREFIX.GET_UPSERT_USER_QUERY_CONFIG, officeId];
}

export function makeBulkUpsertUserPermissionQueryConfigKey({
  officeId,
}: {
  officeId: number;
}): QueryKey {
  return [USER_QUERY_PREFIX.GET_UPSERT_USER_PERMISSION_QUERY_CONFIG, officeId];
}
