import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';

export const VersionContainer = styled.div`
  text-align: center;
  opacity: 0.6;
`;

export const Container = styled(MuiContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 4rem;
` as typeof MuiContainer;
