import { AxiosError } from 'axios';
import { createAction, createReducer } from 'redux-act';
import { isAxiosError, logError } from 'utils/error';
import { ErrorCodes } from 'shared-types';
import { DefaultThunk } from './typings';
import { setOpenEvolveCalcModal } from './commissionPlan';

export interface UiError {
  status: boolean;
  heading?: string;
  body?: string | undefined | string[];
  collapseBody?: string | undefined | string[];
  collapseJsonData?: string | undefined | string[];
  correlationId?: string;
  onClose?: () => void;
  code?: string;
}

export const clearUiState = createAction('commission/UI/CLEAR');
export const loading = createAction<boolean>('commission/UI/SET_LOADING');
export const fullPageLoading = createAction<boolean>('commission/UI/SET_FULLPAGELOADING');
export const setSeenReferalPromo = createAction<boolean>('comission/USER/SEEN_REFERAL_PROMO');
const error = createAction<UiError>('commission/UI/ERROR');

export interface UiState {
  error: UiError;
  loading: boolean;
  isFullPageLoading: boolean;
  seenReferalPromo: boolean;
}
const initialState = {
  error: {
    status: false,
    heading: '',
    body: '',
    collapseBody: '',
    collapseJsonData: '',
    correlationId: '',
  },
  loading: false,
  isFullPageLoading: false,
  seenReferalPromo: false,
};

const reducer = createReducer<UiState>({}, initialState);

reducer.on(clearUiState, () => ({ ...initialState }));

reducer.on(error, (state, payload) => ({
  ...state,
  error: payload,
}));

reducer.on(loading, (state, payload) => ({
  ...state,
  loading: payload,
}));

reducer.on(fullPageLoading, (state, payload) => ({
  ...state,
  isFullPageLoading: payload,
}));

reducer.on(
  setSeenReferalPromo,
  (state, payload): UiState => ({
    ...state,
    seenReferalPromo: payload,
  }),
);

type ShowErrorModalParams = Pick<
  UiError,
  'heading' | 'body' | 'collapseBody' | 'collapseJsonData' | 'correlationId' | 'onClose' | 'code'
>;

export const showErrorModal = (
  {
    heading,
    body,
    code,
    collapseBody,
    collapseJsonData,
    correlationId,
    onClose,
  }: ShowErrorModalParams = {
    heading: 'Ops 😭',
    body: '',
    collapseBody: '',
    collapseJsonData: '',
    correlationId: '',
    code: '',
  },
): DefaultThunk => async dispatch => {
  if (code === ErrorCodes.PAYOUT_PERIOD_LOCKED) {
    dispatch(setOpenEvolveCalcModal(true));
    return;
  }

  dispatch(
    error({
      status: true,
      heading,
      body,
      collapseBody,
      collapseJsonData,
      correlationId,
      onClose,
    }),
  );
};

export const unexpectedError = (err: Error): DefaultThunk => async dispatch => {
  const defaultErrorHeader = 'Ops 😭';
  const defaultErrorMessage =
    'Aconteceu um erro desconhecido, por favor tente mais tarde ou entre em contato conosco!';
  logError(err);
  const customError = isAxiosError(err) ? (err as AxiosError) : undefined;
  dispatch(
    showErrorModal({
      heading: defaultErrorHeader,
      body: defaultErrorMessage,
      correlationId: customError?.response?.headers['x-correlation-id'],
    }),
  );
};

export const showAlertModal = showErrorModal;

export const hideError = (): DefaultThunk => async dispatch => {
  dispatch(
    error({
      status: false,
      heading: '',
      body: '',
      correlationId: '',
      onClose: undefined,
    }),
  );
};

export default reducer;
