import ROUTES from 'pages/routes-enum';
import { matchPath } from 'react-router-dom';

export const shouldOverlayRoute = (pathname?: string): boolean => {
  if (!pathname) return false;
  const overlayRoutes = matchPath(pathname, [
    ROUTES.SELECTED_TAB_COMMISSION_PLAN,
    ROUTES.SELECTED_COMISSION_PLAN,
    ROUTES.DATA_SOURCES_TAB,
    ROUTES.STATEMENT_PANEL_PLAN,
    ROUTES.DATA_SOURCES,
    ROUTES.COMMISSION_PLAN_LIST,
    ROUTES.ROOT,
  ]);

  return !!overlayRoutes?.isExact;
};

export function debounceHandler(func: Function, delay: number) {
  let timeoutId: NodeJS.Timeout;
  function debounce(this: unknown, ...args: unknown[]) {
    const context = this;
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  }
  function cancel() {
    clearTimeout(timeoutId);
  }

  return {
    debounce,
    cancel,
  };
}
