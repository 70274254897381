import {
  AllCommissionsDetails,
  AllCommissionsDetailsResponse,
  BalanceEvents,
  BalanceStatusByCreditorResponse,
  CloseMonthRequest,
  CloseMonthResponse,
  ClosureResponse,
  CreditorBalanceStatusResponse,
  Fechamento,
  GetClosuresResponse,
  OfficeVariablePreference,
  PayrollResponse,
  PreferencesType,
  PreferenceVersionResponse,
  UpdateBalanceStatusRequest,
  UpdateClosurePatchRequestDto,
} from 'shared-types';
import { formatUtc } from 'utils/date';
import Backend from '.';
import { DeleteAumSheetProps, DeleteClosureSheetProps } from './closures/types';

type UpdateClosureState = {
  change: UpdateClosurePatchRequestDto;
  officeId: number;
  date: string;
};

// Backend has the exact same types
export enum CommissionTypes {
  COMMISSION = 0,
  MANUAL = 1,
  SALARY = 2,
}

export const updateClosureState = async ({
  change,
  officeId,
  date,
}: UpdateClosureState): Promise<Fechamento> => {
  const endpoint = `/escritorios/${officeId}/fechamentos/${date}`;
  const response = await Backend.patch<Fechamento>(endpoint, change);
  return response.data;
};

export const closureWithSheet = async (
  sheetMetadata: CloseMonthRequest | CloseMonthRequest[],
  officeId: string,
  date: string,
): Promise<CloseMonthResponse> => {
  const parsedDate = formatUtc(new Date(date), 'yyyy-MM-dd');
  const serviceUrl = `/escritorios/${officeId}/fechamentos/${parsedDate}`;

  const response = await Backend.put<CloseMonthResponse>(serviceUrl, sheetMetadata);

  return response.data;
};

export const fetchClosures = async (officeId: string): Promise<GetClosuresResponse> => {
  const serviceUrl = `/offices/${officeId}/closures`;
  const response = await Backend.get<GetClosuresResponse>(serviceUrl);
  return response.data;
};

export const getPayroll = async ({
  officeId,
  date,
}: {
  officeId: string;
  date: string;
}): Promise<PayrollResponse[] | null | undefined> => {
  const serviceUrl = `/offices/${officeId}/closures/${date}/payroll`;
  const { data } = await Backend.get<PayrollResponse[]>(serviceUrl);
  return data;
};

export const fetchOrigins = async (
  officeId: string | number,
  date: string,
  commissionType: CommissionTypes,
): Promise<string[]> => {
  const serviceUrl = `/offices/${officeId}/closures/${date}/origins`;
  const { data } = await Backend.get<string[]>(serviceUrl, {
    params: { commission_type: commissionType },
  });
  return data;
};

export const getClosureHistoryDates = async ({
  officeId,
  type,
}: {
  officeId: number | undefined;
  type: PreferencesType;
}): Promise<string[]> => {
  const serviceUrl = `/offices/${officeId}/preferences/${type}/dates`;
  const { data } = await Backend.get<string[]>(serviceUrl);
  return data;
};

export const getClosureHistoryByType = async ({
  officeId,
  date,
  type,
}: {
  officeId: number | undefined;
  date: string | undefined;
  type: PreferencesType;
}): Promise<PreferenceVersionResponse[]> => {
  const serviceUrl = `/offices/${officeId}/preferences/${date}/${type}/versions`;
  const { data } = await Backend.get<PreferenceVersionResponse[]>(serviceUrl);
  return data;
};

export const getPreferenceFromDate = async <T>({
  officeId,
  date,
  id,
  type,
}: {
  officeId: number | undefined;
  date: string | null | undefined;
  id: string | null | undefined;
  type: PreferencesType;
}): Promise<T> => {
  const serviceUrl = `/offices/${officeId}/preferences/${date}/${type}/version/${id}`;
  const { data } = await Backend.get<T>(serviceUrl);
  return data;
};

export const putRemoteOrigin = async ({
  officeId,
  date,
  origin,
  params,
}: {
  officeId: number | undefined;
  date: string;
  origin: string;
  params?: Record<string, string | number>;
}): Promise<any> => {
  const { data } = await Backend.put(`/offices/${officeId}/closures/${date}/remote/${origin}`, {
    params,
  });
  return data;
};

export const deleteAumSheet = async ({
  closureDate,
  closureSheetsId,
}: DeleteAumSheetProps): Promise<void> => {
  await Backend.delete(`assets/${closureDate}/file/${closureSheetsId}`);
};

export const deleteClosureSheet = async ({
  officeId,
  closureDate,
  closureSheetsId,
}: DeleteClosureSheetProps): Promise<void> => {
  await Backend.delete(`/offices/${officeId}/closures/${closureDate}/sheets/${closureSheetsId}`);
};

export const getClosureSheetUrl = async ({ fileId }: { fileId: number }): Promise<string> => {
  const { data } = await Backend.get<string>(`/file/${fileId}/url`);
  return data;
};

export const getAllCreditorsEarnings = async (
  officeId: number | undefined,
  closureDate: string,
): Promise<AllCommissionsDetails[]> => {
  const allCommissionsUrl = `/offices/${officeId}/closures/${closureDate}/all-creditors-earnings`;
  const allCommissionsResponse = await Backend.get<AllCommissionsDetailsResponse>(
    allCommissionsUrl,
    { params: { pageSize: 50000 } },
  );

  const allCommissionsDetails: AllCommissionsDetails[] = [];

  let cursor = allCommissionsResponse.data.paging?.cursor ?? 0;

  allCommissionsResponse.data.commissions.forEach(commission => {
    allCommissionsDetails.push(commission);
  });

  while (cursor !== 0) {
    const url = `/offices/${officeId}/closures/${closureDate}/all-creditors-earnings`;

    /* eslint-disable no-await-in-loop */
    const { data } = await Backend.get<AllCommissionsDetailsResponse>(url, {
      params: { cursor, pageSize: 50000 },
    });

    data.commissions.forEach(commission => {
      allCommissionsDetails.push(commission);
    });

    cursor = data.paging?.cursor ?? 0;
  }

  return allCommissionsDetails;
};

export const getAllCreditorsEventStatus = async (
  officeId: number,
  closureDate: string,
): Promise<BalanceStatusByCreditorResponse> => {
  const url = `/offices/${officeId}/closures/${closureDate}/balance-status`;
  const { data } = await Backend.get<BalanceStatusByCreditorResponse>(url);
  return data;
};

export const getCreditorEventsHistory = async (
  officeId: number,
  closureDate: string,
  externalCreditorId: string,
) => {
  const url = `/offices/${officeId}/closures/${closureDate}/creditors/${externalCreditorId}/balance-status/history`;
  const { data } = await Backend.get<BalanceEvents[]>(url);

  return data;
};

export const getBalanceStatus = async (
  officeId: number,
  closureDate: string,
  externalCreditorId: string,
): Promise<CreditorBalanceStatusResponse> => {
  const url = `/offices/${officeId}/closures/${closureDate}/creditors/${externalCreditorId}/balance-status`;
  const { data } = await Backend.get<CreditorBalanceStatusResponse>(url);

  return data;
};

export const updateBalanceStatus = async ({
  officeId,
  closureDate,
  externalCreditorId,
  updateBalancePayload,
}: {
  officeId: number;
  closureDate: string;
  externalCreditorId: string;
  updateBalancePayload: UpdateBalanceStatusRequest;
}): Promise<CreditorBalanceStatusResponse> => {
  const url = `/offices/${officeId}/closures/${closureDate}/creditors/${externalCreditorId}/balance-status`;
  const { data } = await Backend.put<CreditorBalanceStatusResponse>(url, updateBalancePayload);

  return data;
};

export const processMetricSheet = async (
  officeId: number,
  closureDate: string,
  fileId: number,
): Promise<void> => {
  const url = `/offices/${officeId}/closures/${closureDate}/metrics/from-sheet/${fileId}`;
  return Backend.put(url);
};

export const getClosure = async ({
  officeId,
  closureDate,
}: {
  officeId: number;
  closureDate: string;
}): Promise<ClosureResponse> => {
  const { data } = await Backend.get<ClosureResponse>(
    `/offices/${officeId}/closures/${closureDate}`,
  );
  return data;
};

export const applyVariables = async ({
  officeId,
}: {
  officeId: number | undefined;
}): Promise<void> => {
  await Backend.post<OfficeVariablePreference[]>(`/offices/${officeId}/preferences/variables`);
};
export default updateClosureState;

export type TeamsPreferenceResponse = {
  external_creditor_id: string;
  team: string;
};

export const getCurrentClosureTeams = async ({
  officeId,
}: {
  officeId: number | undefined;
  closureDate: string;
}): Promise<TeamsPreferenceResponse[]> => {
  const { data } = await Backend.get<TeamsPreferenceResponse[]>(
    `/offices/${officeId}/creditor/teams`,
  );
  return data;
};
