import React from 'react';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/lib/integration/react';
import store, { persistor } from 'redux-store';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'service/query.client';
import { ptBR } from 'date-fns/locale';
import { AnalyticsProvider } from 'analytics/AnalyticsProvider';
import { createAnalytics } from 'analytics';
import { ThemeContext } from 'theme/themeContext';
import { darkTheme } from './theme';

export const Providers = ({ children }) => {
  const [themeOptions, setThemeOptions] = React.useState(darkTheme);
  const theme = createTheme(themeOptions, ptBR);
  const analytics = createAnalytics();

  return (
    <ThemeContext.Provider value={{ setThemeOptions }}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <QueryClientProvider client={queryClient}>
                <AnalyticsProvider analytics={analytics}>{children}</AnalyticsProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
export default Providers;
