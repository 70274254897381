import { createReducer, createAction } from 'redux-act';
import { getOfficeIframeUrl, getCreditorIframeUrl } from '../service/dashboard.service';
import { unexpectedError } from './ui';

export interface DashboardsState {
  creditorEarningsIframe?: string;
  officeReportIframe?: string;
  selectedCreditor?: string;
}

const initialState = {};
export const setCreditorReports = createAction<string>('dashboards/CREDITOR/REPORTS');
export const setOfficeReports = createAction<string>('dashboards/OFFICE/REPORTS');
export const setSelectedCreditor = createAction<string | undefined>('dashboards/SELECTED/CREDITOR');

const reducer = createReducer<DashboardsState>({}, initialState);

reducer.on(
  setCreditorReports,
  (state, payload): DashboardsState => ({
    ...state,
    creditorEarningsIframe: payload,
  }),
);

reducer.on(
  setOfficeReports,
  (state, payload): DashboardsState => ({
    ...state,
    officeReportIframe: payload,
  }),
);

reducer.on(
  setSelectedCreditor,
  (state, payload): DashboardsState => ({
    ...state,
    selectedCreditor: payload,
  }),
);

export const fetchCreditorDashboard = () => async dispatch => {
  try {
    const data = await getCreditorIframeUrl();
    dispatch(setCreditorReports(data));
  } catch (error) {
    dispatch(unexpectedError(error as Error));
  }
};

export const fetchOfficeDashboard = () => async dispatch => {
  try {
    const data = await getOfficeIframeUrl();
    dispatch(setOfficeReports(data));
  } catch (error) {
    dispatch(unexpectedError(error as Error));
  }
};

export default reducer;
