import { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getImpersonatedUser } from 'modules/user/impersonation';

export class AxiosUtils {
  static injectCorrelationId(config: AxiosRequestConfig) {
    const newConfig: AxiosRequestConfig = { ...config };
    newConfig.headers['x-correlation-id'] = uuidv4();
    return newConfig;
  }

  static injectImpersonationUser(config: AxiosRequestConfig) {
    const impersonationUser = getImpersonatedUser();
    if (impersonationUser) {
      const newConfig: AxiosRequestConfig = { ...config };
      newConfig.headers['x-spltc-impersonate'] = impersonationUser.externalCreditorId;
      return newConfig;
    }

    return config;
  }

  static getFilenameFromContentDisposition(headerVal: string): string | undefined {
    if (!headerVal.includes('filename')) return undefined;
    const split = headerVal.split('filename=');
    return split[1];
  }

  static convertArrayBufferToJson<R>(buff: ArrayBuffer): R {
    const data = new TextDecoder('utf-8').decode(buff);
    return JSON.parse(data);
  }
}
