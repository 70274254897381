import { toast, ToastContent } from 'react-toastify';

export const showInfoToast = (content: ToastContent) =>
  toast.info(content, {
    position: 'bottom-right',
    autoClose: 2200,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const showWarningToast = (content: ToastContent) =>
  toast.warn(content, {
    position: 'bottom-right',
    autoClose: 2200,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const showErrorToast = (content: ToastContent) =>
  toast.error(content, {
    position: 'bottom-right',
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
