import axios from 'axios';
import ROUTES from 'pages/routes-enum';
import { extraErrorData, logMessage } from 'utils/error';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import { AxiosUtils } from 'utils/service/axios';
import { BackendErrorCodes } from 'shared-types';
import { JwtUtils } from 'utils/jwt';
import { getAccessToken, refreshToken } from './auth.service';
import Configuration from '../configuration/configuration';

const LOGOUT_STATUS_CODE = 401;

const AuthenticatedCommissionRemote = axios.create({
  baseURL: Configuration.backendUrl,
});

const addErrorHeaders = (error: any) => {
  extraErrorData('response-headers', error?.response?.headers);
  extraErrorData('request-header', error?.config?.headers);
};

axiosBetterStacktrace(AuthenticatedCommissionRemote);

AuthenticatedCommissionRemote.interceptors.request.use(
  /* eslint-disable no-param-reassign */
  /* parameter reassign is the axios way of work :( */
  config => {
    AxiosUtils.injectCorrelationId(config);
    AxiosUtils.injectImpersonationUser(config);
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

AuthenticatedCommissionRemote.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    const errorResponse = error?.response;
    if (!errorResponse) return Promise.reject(error);

    const shouldTryRefreshToken =
      errorResponse.data.code === BackendErrorCodes.AUTHENTICATION_FAILED && getAccessToken();
    if (shouldTryRefreshToken) {
      try {
        const token = getAccessToken();
        const payload = JwtUtils.readJwtPart(token);
        if (payload.type === 'saml') {
          localStorage.clear();
          window.location.href = `/signin/${payload.slug}?redirectTo=${window.location.href}`;
          return await AuthenticatedCommissionRemote(originalRequest);
        }

        await refreshToken();
        AuthenticatedCommissionRemote.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;
        // eslint-disable-next-line @typescript-eslint/return-await
        return AuthenticatedCommissionRemote(originalRequest);
      } catch (refreshTokenError) {
        addErrorHeaders(error);
        logMessage('Couldnt refresh token, redirecting to logout');
        window.location.href = ROUTES.LOGOUT;

        return Promise.reject(refreshTokenError);
      }
    }

    const statusCode = errorResponse.status;
    const shouldLogout = LOGOUT_STATUS_CODE === statusCode && !shouldTryRefreshToken;
    if (shouldLogout) {
      window.location.href = ROUTES.LOGOUT;
    }

    if (statusCode >= 400) {
      addErrorHeaders(error);
    }

    return Promise.reject(error);
  },
);

export default AuthenticatedCommissionRemote;
