import { useTheme } from '@material-ui/core';
import * as React from 'react';
import { iconBorderColor, iconFillColor } from '../../theme';
import { DefaultIconType } from './types';

export const CalcIcon: React.FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3a3 3 0 0 0-3 3v4h18V6a3 3 0 0 0-3-3H6Zm-3.536-.536A5 5 0 0 1 6 1h12a5 5 0 0 1 5 5v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a5 5 0 0 1 1.464-3.536ZM14.5 16.5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.293 18.268a1 1 0 0 1 0-1.414l2.121-2.122a1 1 0 0 1 1.414 1.415l-2.12 2.12a1 1 0 0 1-1.415 0Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.829 18.268a1 1 0 0 1-1.415 0l-2.12-2.122a1 1 0 0 1 1.413-1.414l2.122 2.122a1 1 0 0 1 0 1.414Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v7a5 5 0 0 1-5 5H6a5 5 0 0 1-5-5v-7Zm12 10h5a3 3 0 0 0 3-3v-6h-8v9Zm-2-9v9H6a3 3 0 0 1-3-3v-6h8Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.293 18.268a1 1 0 0 1 0-1.415l2.121-2.12a1 1 0 0 1 1.414 1.413l-2.12 2.122a1 1 0 0 1-1.415 0Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.829 18.267a1 1 0 0 1-1.415 0l-2.12-2.12a1 1 0 1 1 1.413-1.415l2.122 2.121a1 1 0 0 1 0 1.414Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
