export enum LocalStorageKeys {
  SELECTED_COLUMNS = 'selected_columns',
}

export function getLocalStorageJson<T>(key: LocalStorageKeys) {
  const item = localStorage.getItem(key);
  if (!item) return undefined;

  try {
    return JSON.parse(item) as T;
  } catch {
    return undefined;
  }
}

export function setLocalStorageJson<T>(key: LocalStorageKeys, value: T) {
  localStorage.setItem(key, JSON.stringify(value));
}

export const setOrRemoveLocalStorage = (key: string | LocalStorageKeys, value?: string) => {
  if (value) localStorage.setItem(key, value);
  else localStorage.removeItem(key);
};
