import { AxiosError } from 'axios';
import { BackendErrorCodes, ErrorCodes } from 'shared-types';

// TODO, REFACT: This should be in a generic error handler
export const errorMessages = {
  [ErrorCodes.DATASOURCE_ID_REQUIRED]: 'O ID da base de dados é obrigatório',
  [ErrorCodes.TAB_SCHEMA_MISSMATCH]: 'Os esquemas da aba e fonte de dados não correspondem',
  [ErrorCodes.DUPLICATED_DATASOURCE_CONFIG]:
    'Este conjunto de dados ou planilha já está presente nesta aba',
  [BackendErrorCodes.EXTERNAL_CREDITOR_ID_ALREADY_REGISTERED]: 'Este usuário já está cadastrado.',
  [ErrorCodes.TAB_METADATA_NOT_FOUND]: 'Metadados da aba não encontrados.',
  [ErrorCodes.TAB_NOT_FOUND]: 'Não foi possível encontrar a aba selecionada.',
  [ErrorCodes.DATASOURCE_NOT_FOUND]: 'Não foi possível encontrar a base de dados selecionada.',
  [ErrorCodes.GOOGLE_SHEET_NOT_FOUND]: 'Google Sheet não encontrada.',
  [ErrorCodes.WORKSHEET_NOT_FOUND]: 'Planilha não encontrada',
  [ErrorCodes.GROUP_BY_INVALID_SOURCE_COLUMN]: 'Coluna de origem inválida',
  [ErrorCodes.INTERNAL_ERROR]: 'Ocorreu um erro interno.',
  [ErrorCodes.JOINED_TAB_NOT_FOUND]: 'Não foi possível encontrar a aba selecionada.',
  [ErrorCodes.MATCH_TAB_NOT_FOUND]: 'Não foi possível encontrar a aba selecionada.',
  [ErrorCodes.JOINED_COLUMN_NOT_FOUND]: 'Não foi possível encontrar a coluna selecionada.',
  [ErrorCodes.MATCH_COLUMN_NOT_FOUND]: 'Não foi possível encontrar a coluna selecionada.',
  [ErrorCodes.SFTP_CONNECTION_ERROR]: 'Não foi possível conectar com essas credenciais.',
  [ErrorCodes.S3_CONNECTION_ERROR]: 'Não foi possível conectar com essas credenciais.',
  [ErrorCodes.FILTERED_TAB_COLUMN_NOT_FOUND]: 'Coluna da aba filtrada não foi encontrada.',
  [ErrorCodes.FILTERED_TAB_RESULT_COLUMN_NOT_FOUND]:
    'Coluna de resultado da aba filtrada não foi encontrada.',
  [ErrorCodes.FILTERED_TAB_NOT_FOUND]: 'Aba filtrada não encontrada.',
  [ErrorCodes.FORMULA_SEMANTIC_ERROR]: 'Erro semântico na fórmula.',
  [ErrorCodes.MALFORMED_FORMULA]: 'Formula inválida.',
  [ErrorCodes.FORMULA_NON_EXISTENT_COLUMN]: 'Formula não existe na coluna.',
  [ErrorCodes.PROCV_BASE_TAB_NOT_FOUND]: 'Aba base do procv não encontrada.',
  [ErrorCodes.JOIN_CYCLIC_DEPENDENCY]: 'Dependência cíclica no join.',
  [ErrorCodes.PROCV_CYCLIC_DEPENDENCY]: 'Dependência cíclica no procv.',
  [ErrorCodes.PROCV_CYCLIC_MATCH_DEPENDENCY]: 'Dependência cíclica no match do procv.',
  [ErrorCodes.PROCV_MATCH_COLUMN_NOT_FOUND]: 'Coluna de match do procv não encontrada.',
  [ErrorCodes.PROCV_ORDER_COLUMN_NOT_FOUND]: 'Coluna da ordenação do procv não encontrada.',
  [ErrorCodes.PROCV_RESULT_COLUMN_NOT_FOUND]: 'Coluna de resultado do procv não encontrada.',
  [ErrorCodes.PROCV_FIELD_COLUMN_NOT_FOUND]: 'Coluna de campo do procv não encontrada.',
  [ErrorCodes.PROCV_JOINED_COLUMN_NOT_FOUND]: 'Coluna de junção do procv não encontrada.',
  [ErrorCodes.JOINED_RESULT_COLUMN_NOT_FOUND]: 'Coluna de resultado do join não encontrada.',
  [ErrorCodes.UNION_COLUMN_NOT_FOUND]: 'Coluna da união não encontrada.',
  [ErrorCodes.UNION_RESULT_COLUMN_NOT_FOUND]: 'Coluna de resultado da união não encontrada.',
  [ErrorCodes.UNION_TAB_NOT_FOUND]: 'Aba da união não encontrada.',
  [ErrorCodes.UNION_TAB_CYCLIC_DEPENDENCY]: 'Dependência cíclica na aba da união.',
  [ErrorCodes.UNION_TAB_COLUMN_QTD_MISMATCH]:
    'Quantidade de colunas da aba da união não corresponde a quantidade de colunas de resultado.',
  [ErrorCodes.REFERENCED_COLUMN_NOT_FOUND]: 'Coluna de referência não encontrada.',
  [ErrorCodes.INVALID_RESULT_COLUMN_ID]: 'Coluna de resultado invalida ou duplicada',
  [ErrorCodes.PAYOUT_PERIOD_LOCKED]:
    'Ops 😢, parece que há um ou mais períodos deste plano de cálculo trancados. Destranque-os para sincronizar.',
  [ErrorCodes.SOME_PLAN_IS_SYNCING]:
    'Existe uma sincronização em andamento. Tente novamente mais tarde.',
};

export const parseErrorMessage = (error: ErrorCodes): { body: string; hasMessage: boolean } => {
  if (!error || !errorMessages[error]) return { body: 'Ops, algo deu errado', hasMessage: false };
  return { body: errorMessages[error], hasMessage: true };
};

export const errorGenerator = (error: AxiosError) => {
  const responseData = error.response?.data;
  const { body, hasMessage } = parseErrorMessage(responseData?.data?.code);
  const payloadError = responseData?.data ?? responseData;
  const code = responseData?.data?.code;

  const isClassValidatorError =
    payloadError?.statusCode === 400 &&
    payloadError?.error === 'Bad Request' &&
    !!payloadError?.message;
  const isZodError = error.response?.status === 400 && !!responseData?.data?.errors?.length;

  const unmappedError =
    payloadError?.code && typeof payloadError?.code === 'string' && !!payloadError?.message;

  const correlationId = error.response?.headers?.['x-correlation-id'];

  if (hasMessage) {
    return {
      heading: 'Erro',
      body,
      correlationId,
      code,
    };
  }
  if (isClassValidatorError) {
    return {
      heading: 'Ops 😭',
      body: 'Aconteceu um erro de configuração inválida, clique para ver mais.',
      collapseBody: payloadError.message,
      correlationId,
      code,
    };
  }

  if (isZodError) {
    return {
      heading: 'Ops 😭',
      body: 'Aconteceu um erro de configuração inválida, clique para ver mais.',
      collapseBody: responseData?.data?.errors
        ? JSON.stringify(responseData?.data?.errors, null, 4)
        : undefined,
      correlationId,
      code,
    };
  }

  if (unmappedError) {
    return {
      heading: 'Ops 😭',
      body: 'Ops, algo deu errado, clique para ver mais informações.',
      collapseBody: payloadError.message,
      collapseJsonData: payloadError?.data && JSON.stringify(payloadError.data),
      correlationId,
      code,
    };
  }
  if (responseData?.code && responseData?.message && responseData?.data) {
    return {
      heading: 'Ops 😭',
      body: responseData?.message,
      collapseBody: JSON.stringify(responseData?.data, null, 4),
      correlationId,
      code,
    };
  }
  return {
    heading: 'Erro',
    body,
    correlationId,
    code,
  };
};
