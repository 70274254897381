import React from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from './loading.json';
import { defaultOpts } from '.';

interface Props {
  width?: string;
  height?: string;
}

const Loading = ({ width, height }: Props) => {
  return (
    <Lottie
      {...defaultOpts}
      width={width ?? defaultOpts.width}
      height={height ?? defaultOpts.height}
      options={{ ...defaultOpts.options, animationData: loadingAnimation }}
    />
  );
};

export default Loading;
