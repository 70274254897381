import { UsersClaims } from 'shared-types';
import { useSelector } from 'react-redux';
import { RootState } from './typings';
import { userHasSomeClaim } from '../utils/claim';

const getClaim = name => state => {
  const selected = state.user.claims?.filter(claim => claim.atributo === name);
  const value = selected && selected[0]?.valor;
  return value;
};

export const hasPermission = (allowedClaims: UsersClaims[] | undefined) => state => {
  if (!allowedClaims) return false;

  return userHasSomeClaim(allowedClaims, state.user?.claims ?? []);
};

export const getName = () => state => {
  return state.user.credor?.nome_credor;
};

export const getUser = () => state => {
  return state.user;
};

export const getCreditorId = () => (state: RootState) => {
  return state.user.impersonatedUser?.creditorId;
};

export const getOffice = (state: RootState) => {
  return state.user.escritorio;
};

export const getOfficeName = (state: RootState) => {
  return state.user.escritorio?.nome_escritorio;
};

export const getOfficeId = state => {
  return state.user.escritorio?.id_escritorio;
};
export const useOfficeId = () => useSelector(getOfficeId);

export const getCreditorsFromOffice = (state: RootState) => {
  return state.user.creditorsFromOffice;
};

export const getImpersonatedUser = (state: RootState) => {
  return state.user.impersonatedUser;
};

export const getEntireModule = (state: RootState) => {
  return state.user;
};

export default getClaim;
