import { UsersClaims, Claim } from 'shared-types';
import { customBoolDict, stringAsBool } from './text';

const hasPermission = (
  userClaims: Claim[] | undefined | null,
  allowedClaims: UsersClaims[],
): boolean => {
  if (!userClaims || !allowedClaims) return false;
  return userClaims.some(
    userClaim =>
      allowedClaims.map(allowed => allowed.toString()).includes(userClaim.atributo) &&
      stringAsBool(String(userClaim.valor), true, true, customBoolDict),
  );
};

export const getPermission = (
  userClaims: Claim[] | undefined | null,
  claimToSearch: UsersClaims,
): string | null => {
  if (!userClaims) return null;
  const value = userClaims.filter(userClaim => userClaim.atributo === claimToSearch.toString());
  if (value.length === 0) return null;
  if (!value[0]) return null;
  return String(value[0].valor);
};

export const claimDictionary = {
  [UsersClaims.OFFICE_CREDITORS_VIEW]: 'Ver resultados de todas pessoas',
  [UsersClaims.TEAM_CREDITORS_VIEW]: 'Ver resultados das pessoas do time',
  [UsersClaims.CLOSURE_UNFINISHED_VIEW]: 'Ver fechamentos ocultos',
  [UsersClaims.CLOSURE_UPDATE]: 'Criar e modificar fechamentos',
  [UsersClaims.USER_CREATE]: 'Criar e modificar usuários',
  [UsersClaims.PAYROLL_VIEW]: 'Visualizar folha de pagamento',
  [UsersClaims.PAYSLIP_VIEW]: 'Visualizar repasses detalhados',
  [UsersClaims.PERMISSION_PAYMENTS]: 'Realizar pagamentos',
};

export default hasPermission;
