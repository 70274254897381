import { combineReducers } from 'redux';
import { clearState } from 'service/storage.service';
import dashboards from './dashboards';
import user from './user';
import monthclosure from './domonthclosure';
import ui from './ui';
import commission from './commission';
import rules from './rules';
import rulesV2 from './rulesV2';
import dataSources from './dataSources';
import manualtab from './manualtab';
import commissionPlan from './commissionPlan';

const reducers = combineReducers({
  user,
  monthclosure,
  commission,
  dashboards,
  ui,
  rules,
  rulesV2,
  dataSources,
  manualtab,
  commissionPlan,
});

const rootReducer = (
  state: ReturnType<typeof reducers>,
  action: { type: 'commission/ROOT/RESET_STATE' },
) => {
  if (action.type === 'commission/ROOT/RESET_STATE') {
    clearState();
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export default rootReducer;
