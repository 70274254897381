class FirebaseConfiguration {
  readonly apiKey: any = import.meta.env.VITE_FIREBASE_API_KEY;

  readonly authDomain: any = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN;

  readonly databaseURL: any = import.meta.env.VITE_FIREBASE_DATABASE_URL;

  readonly projectId: any = import.meta.env.VITE_FIREBASE_PROJECT_ID;

  readonly storageBucket: any = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET;

  readonly messagingSenderId: any = import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID;

  readonly appId: any = import.meta.env.VITE_FIREBASE_APP_IP;

  readonly measurementId: any = import.meta.env.VITE_FIREBASE_MEASUREMENT_ID;
}

export default FirebaseConfiguration;
