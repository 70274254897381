import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { queryClient } from 'service/query.client';
import { makeGetUserKey } from 'service/user';
import { getRemoteUser } from 'service/user.service';
import {
  Claim,
  claimMap,
  CurrentUserResponse,
  findAndParseClaim,
  PlanGroupPermission,
  UserPreferences,
  UsersClaims,
} from 'shared-types';
import { z } from 'zod';

const ONE_MINUTE_STALE_TIME = 60 * 1000;

type InferedClaimReturnType<T extends UsersClaims> = {
  data?: ClaimSchemaType<T>;
  success: boolean;
};

export type ClaimSchemaType<T extends UsersClaims> = z.infer<typeof claimMap[T]['schema']>;

type UseGetClaimsType = {
  claims: Claim[];
  isLoading: boolean;
  findAndUseClaim: <T extends UsersClaims>(claim: T) => InferedClaimReturnType<T>;
  hasClaims: (claims: UsersClaims[]) => boolean;
  hasOneOrOther: (claims: UsersClaims[]) => boolean;
  authorizeGroupedPlanAccess: (
    permission: PlanGroupPermission | 'can_unlock_periods',
    group_id: number,
    type?: 'calc' | 'sources',
  ) => boolean;
};

export const userHasEveryClaim = (claims: UsersClaims[], userClaims: Claim[]): boolean => {
  const selectedClaims = claims.map(claim => findAndParseClaim(claim, userClaims));
  return selectedClaims.every(claim => claim.success && claim.data);
};

export const userHasSomeClaim = (claim: UsersClaims[], userClaims: Claim[]): boolean => {
  const selectedClaims = claim.map(c => findAndParseClaim(c, userClaims));
  return selectedClaims.some(c => c.success && c.data);
};

/**
 * Hook to get the user claims
 * @returns {claims: Claim[], findAndUseClaim: <T extends UsersClaims>(claim: T) => InferedClaimReturnType<T>, hasClaims: (claims: UsersClaims[]) => boolean, hasOneOrOther: (claims: UsersClaims[]) => boolean}
 * @example
 * const { claims, findAndUseClaim ,hasOneOrOther, hasClaims } = useGetClaims();
 * const { data: hideAmounts } = findAndUseClaim(UsersClaims.HIDE_AMOUNTS);
 * const hasClaims = hasClaims([UsersClaims.HIDE_AMOUNTS, UsersClaims.MENU_OPENED]);
 * const hasOneOrOther = hasOneOrOther([UsersClaims.HIDE_AMOUNTS, UsersClaims.MENU_OPENED]);
 * */

export const useGetClaims = (): UseGetClaimsType => {
  const { data: user, isLoading } = useQuery(makeGetUserKey(), getRemoteUser, {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: ONE_MINUTE_STALE_TIME,
  });

  const claims = useMemo(() => user?.claims ?? [], [user]);

  /**
   * Find the specified claim and parse it's value
   * @param claim
   * @returns {data: T, success: boolean}
   * @example
   * const { data: hideAmounts } = findAndUseClaim(UsersClaims.HIDE_AMOUNTS);
   *
   *
   */

  const findAndUseClaim = <Claim extends UsersClaims>(
    claim: Claim,
  ): InferedClaimReturnType<Claim> => {
    return findAndParseClaim(claim, claims);
  };

  /**
   * Check if all claims are present and valid
   * @param claim
   * @returns {boolean}
   * @example
   * const hasClaims = hasClaims([UsersClaims.HIDE_AMOUNTS, UsersClaims.MENU_OPENED]);
   *  */

  const hasClaims = (claim: UsersClaims[]): boolean => {
    return userHasEveryClaim(claim, claims);
  };

  /**
   * Check if at least one claim is present and valid
   * @param claim
   * @returns {boolean}
   * @example
   * const hasOneOrOther = hasOneOrOther([UsersClaims.HIDE_AMOUNTS, UsersClaims.MENU_OPENED]);
   * */

  const hasOneOrOther = (claim: UsersClaims[]): boolean => {
    return userHasSomeClaim(claim, claims);
  };

  function authorizeGroupedPlanAccess(
    permission: PlanGroupPermission | 'can_unlock_periods',
    group_id: number,
    type?: 'calc' | 'sources',
  ): boolean {
    const { data: planGroupPermissions } = findAndUseClaim(UsersClaims.PLAN_GROUP_PERMISSIONS);
    const { data: isAdmin } = findAndUseClaim(UsersClaims.GENERIC_DATA_IMPORT);

    if (isAdmin) return true;
    if (!planGroupPermissions) return false;

    const planGroupPerm = planGroupPermissions.plan_permissions.find(
      perm => perm.group_id === group_id,
    );
    if (!planGroupPerm) return false;

    if (permission === 'can_unlock_periods') return planGroupPerm.can_unlock_periods;
    if (!type) return false;

    return planGroupPerm[type].includes(permission);
  }

  return {
    findAndUseClaim,
    hasClaims,
    hasOneOrOther,
    claims,
    authorizeGroupedPlanAccess,
    isLoading,
  };
};

/**
 * Updates the selected date value in the user's claims and updates the user data in the react query's cache.
 *
 * @param selectedDate - The new selected date value.
 */
export const changeSelectedDateClaimValue = (selectedDate: string) => {
  const mapClaims = (claims: Claim[]) =>
    claims.map(claim => {
      if (claim.atributo === UsersClaims.USER_PREFERENCES) {
        const calimValue: UserPreferences = JSON.parse(claim.valor as string);

        calimValue.selectedDate = selectedDate;

        return {
          ...claim,
          valor: JSON.stringify(calimValue),
        };
      }

      return claim;
    });

  queryClient.setQueryData<CurrentUserResponse | undefined>('user', old =>
    old
      ? {
          ...old,
          claims: mapClaims(old.claims),
        }
      : undefined,
  );
};
