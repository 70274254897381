import { logEvent, setCurrentScreen, setUserId, setUserProperties } from 'firebase/analytics';
import { useContext } from 'react';
import { useGetUser } from 'service/user';
import { AnaliticsContext } from './AnalyticsProvider';
import EVENTS from './events';

export type TrackEventsType = {
  track: (
    event: EVENTS | string,
    eventInfo?:
      | {
          [key: string]: unknown;
        }
      | undefined,
  ) => void;
  setScreen: (path: string) => void;
};

export const useAnalytics = (): TrackEventsType => {
  const { data: loggedUser } = useGetUser();
  const context = useContext(AnaliticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  const { analytics } = context;
  if (loggedUser && analytics) {
    setUserId(analytics, String(loggedUser.credor?.id_credor));
    setUserProperties(analytics, {
      version: import.meta.env.VITE_COMMIT,
      officeId: String(loggedUser.escritorio.id_escritorio),
      officeName: loggedUser.escritorio?.nome_escritorio
        ? loggedUser.escritorio.nome_escritorio
        : '',
      screenDimension: `${window.innerWidth}x${window.innerHeight}`,
    });
  }

  const track = (event: EVENTS | string, eventInfo?: { [key: string]: unknown }) => {
    if (!analytics) return;
    logEvent(analytics, event, eventInfo);
  };

  const setScreen = (location: string) => {
    if (!analytics) return;
    setCurrentScreen(analytics, location);
  };

  return {
    track,
    setScreen,
  };
};
