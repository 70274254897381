import * as React from 'react';
import { useTheme } from '@material-ui/core';
import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const DataSource: React.FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 17V7C20 4.832 16.337 3 12 3C7.663 3 4 4.832 4 7V17C4 19.168 7.663 21 12 21C16.337 21 20 19.168 20 17ZM12 5C15.691 5 17.931 6.507 18 6.994C17.931 7.493 15.691 9 12 9C8.309 9 6.069 7.493 6 7.006C6.069 6.507 8.309 5 12 5ZM6 9.607C7.479 10.454 9.637 11 12 11C14.363 11 16.521 10.454 18 9.607V11.994C17.931 12.493 15.691 14 12 14C8.309 14 6.069 12.493 6 12V9.607ZM6 17V14.607C7.479 15.454 9.637 16 12 16C14.363 16 16.521 15.454 18 14.607V16.994C17.931 17.493 15.691 19 12 19C8.309 19 6.069 17.493 6 17Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path
        d="M20 7C20 4.832 16.337 3 12 3C7.663 3 4 4.832 4 7C4 8.2 5.33333 9.238 6 9.607C7.479 10.454 9.637 11 12 11C14.363 11 16.521 10.454 18 9.607C18.6667 9.238 20 8.2 20 7ZM12 5C15.691 5 17.931 6.507 18 6.994C17.931 7.493 15.691 9 12 9C8.309 9 6.069 7.493 6 7.006C6.069 6.507 8.309 5 12 5Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
