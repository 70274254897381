import { differenceInMinutes, parseISO } from 'date-fns';
import history from 'pages/history';
import ROUTES from 'pages/routes-enum';
import { useEffect, useRef } from 'react';
import { UsersClaims } from 'shared-types';
import { useGetClaims } from 'utils/claim';

const TIMEOUT_DELAY_MS = 1000;

/**
 * Provides functionality for automatic user logout due to inactivity.
 *
 * This hook monitors user activity and logs them out if they remain inactive for a specified duration.
 * It relies on the 'FEATURE_INACTIVITY_LOGOUT' claim to determine the inactivity timeout.
 
    @see {@link https://github.com/splitc-com-br/shared-types/blob/master/user/claims/index.ts#L551}    
*/
export function useLogoutByInactivity() {
  const lastTimeoutDebounce = useRef<NodeJS.Timeout>();
  const { findAndUseClaim } = useGetClaims();

  const inactivityLogoutClaim = findAndUseClaim(UsersClaims.FEATURE_INACTIVITY_LOGOUT);

  function updateLastTimeoutDebounced() {
    if (lastTimeoutDebounce.current) {
      clearTimeout(lastTimeoutDebounce.current);
    }

    lastTimeoutDebounce.current = setTimeout(() => {
      localStorage.setItem('lastActiveDate', new Date().toISOString());
    }, TIMEOUT_DELAY_MS);
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (inactivityLogoutClaim.data) {
      intervalId = setInterval(() => {
        const lastActiveDate = localStorage.getItem('lastActiveDate');

        if (
          inactivityLogoutClaim?.data?.expiresInMinutes &&
          lastActiveDate &&
          differenceInMinutes(new Date(), parseISO(lastActiveDate)) >=
            inactivityLogoutClaim?.data?.expiresInMinutes
        ) {
          history.push(ROUTES.LOGOUT);
        }
      }, TIMEOUT_DELAY_MS);

      window.addEventListener('mousemove', updateLastTimeoutDebounced);
      window.addEventListener('keydown', updateLastTimeoutDebounced);
    }

    return () => {
      if (inactivityLogoutClaim.data) {
        clearInterval(intervalId);

        window.removeEventListener('mousemove', updateLastTimeoutDebounced);
        window.removeEventListener('keydown', updateLastTimeoutDebounced);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactivityLogoutClaim]);
}
