/* eslint import/no-duplicates: "off" */
import {
  parseISO,
  sub,
  isValid,
  addMinutes,
  parse,
  format as originalFormat,
  differenceInCalendarDays,
  isBefore,
  startOfMonth,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

export { ptBR } from 'date-fns/locale';

export { addMinutes, sub, differenceInCalendarDays, parseISO, originalFormat, startOfMonth };

export const format = (date: Date, formatPattern: string) =>
  originalFormat(date, formatPattern, { locale: ptBR });

export const formatISO = (date: Date, pattern = 'yyyy-MM-dd') => format(date, pattern);

export const formatUtc = (date: Date, formatPattern = 'yyyy-MM-dd') =>
  originalFormat(addMinutes(date, date.getTimezoneOffset()), formatPattern, { locale: ptBR });

export const formatUtcFromString = (
  date: string,
  inputFormat = 'dd/MM/yyyy',
  outputFormat = 'yyyy-MM-dd',
): string => {
  const parsed = parse(date, inputFormat, new Date());
  if (!isValid(parsed)) return '';
  return originalFormat(parsed, outputFormat);
};

export const formatDate = (date: Date, outputFormat = 'dd/MM/yyyy', locale?: Locale): string => {
  if (!isValid(date)) return '';
  return originalFormat(date, outputFormat, { locale });
};

export const tryParse = (date: string, timeFormat: string = 'yyyy-MM-dd'): Date => {
  return parse(date, timeFormat, new Date());
};

export const formatTimeStamp = (timeStamp: string, pattern = 'dd/MM/yyyy HH:mm:ss') => {
  const date = parseISO(timeStamp);
  return format(date, pattern);
};

export const prettyFormatFromIso = (
  date: string,
  outputFormat = 'dd/MM/yyyy',
  locale?: Locale,
): string => {
  const parsed = parseISO(date);
  return formatDate(parsed, outputFormat, locale);
};

export const now = () => new Date();

export const sortDate = (firstDate: Date, secondDate: Date): number => {
  if (isBefore(firstDate, secondDate)) return -1;
  if (isBefore(secondDate, firstDate)) return 1;
  return 0;
};

export const sortDateStrings = (firstStringDate: string, secondStringDate: string): number => {
  const firstDate = new Date(firstStringDate);
  const secondDate = new Date(secondStringDate);
  return sortDate(firstDate, secondDate);
};

export const daysDistance = (date: string) => differenceInCalendarDays(now(), new Date(date));

export const formatPeriodRange = (
  startDate: string,
  endDate: string,
  outputFormat = "d 'de' MMMM yyyy",
  delimiter = 'a',
) => {
  return `${prettyFormatFromIso(startDate, outputFormat, ptBR)} ${delimiter} ${prettyFormatFromIso(
    endDate,
    outputFormat,
    ptBR,
  )}`;
};

export function isGreaterThan(firstDate?: string, secondDate?: string) {
  if (!firstDate || !secondDate) return false;
  return firstDate.localeCompare(secondDate) > 0;
}

export const formatPeriodDate = (date: string, pattern: string) =>
  formatISO(parseISO(date), pattern);

export const formatDateTime = (date: Date) =>
  new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).format(date);
