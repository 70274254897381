import {
  AdjustmentApprovalRequest,
  CommissionResponse,
  CreateAdjustmentRequest,
  CreditorEarnedAmountResponse,
  DataSourceRequest,
  DivisionSiblingsResponse,
  GetClosuresResponse,
  GetCreditorEarnings,
  IComissao,
  InsertCommissionRequest,
  PaycheckResponse,
  PreferencesType,
  SalaryPreference,
} from 'shared-types';
import { fetchingWithPaginationPartitions } from 'utils/pagination';
import Remote from '.';
import ClosureBackend from './closure.backend';
import { CommissionTypes } from './closure.service';
import { DeleteClosureProps } from './closures/types';

const GET_EARNINGS_PAGE_SIZE = 25000;

export const getAllCommissions = async (officeId: number, creditor: string): Promise<IComissao> => {
  const { data } = await Remote.get('/comissoes', {
    params: {
      id_escritorio: officeId,
      id_credor_externo: creditor,
    },
  });
  return data;
};

export const getCreditorEarnedAmount = async ({
  externalCreditorId,
  date,
  officeId,
}: {
  externalCreditorId: string;
  date: string;
  officeId: number;
}): Promise<CreditorEarnedAmountResponse> => {
  const { data } = await Remote.get(
    `/offices/${officeId}/closures/${date}/${encodeURIComponent(externalCreditorId)}/earned-amount`,
  );
  return data;
};

export const getClosuresMonths = async (officeId: number): Promise<GetClosuresResponse> => {
  const { data } = await Remote.get(`offices/${officeId}/closures`);
  return data;
};

export const getCommission = async (officeId: number, creditor: string): Promise<number> => {
  const commissions = await getAllCommissions(officeId, creditor);
  return commissions.resultados[0].resultado;
};

export const getCommissionDetails = async ({
  officeId,
  creditor,
  commissionDate,
}: {
  officeId: number;
  creditor: string;
  commissionDate: string;
}): Promise<GetCreditorEarnings['results']> => {
  const request = async (from?: number, to?: number) => {
    const { data } = await Remote.get<GetCreditorEarnings>(
      `/offices/${officeId}/creditors/${encodeURIComponent(creditor)}/commissions`,
      {
        params: {
          pageSize: GET_EARNINGS_PAGE_SIZE,
          to,
          from,
          closureDate: commissionDate,
        },
      },
    );
    return data;
  };

  return fetchingWithPaginationPartitions(request);
};

export const getOwnCommissions = async ({
  officeId,
  creditor,
  commissionDate,
}: {
  officeId: number;
  creditor: string;
  commissionDate: string;
}): Promise<GetCreditorEarnings['results']> => {
  const request = async (from?: number, to?: number) => {
    const { data } = await Remote.get<GetCreditorEarnings>(
      `/offices/${officeId}/creditors/${encodeURIComponent(creditor)}/commissions/owned`,
      {
        params: {
          pageSize: GET_EARNINGS_PAGE_SIZE,
          to,
          from,
          closureDate: commissionDate,
        },
      },
    );
    return data;
  };

  return fetchingWithPaginationPartitions(request);
};

export const deleteClosure = async ({ officeId, closureDate }: DeleteClosureProps): Promise<void> =>
  ClosureBackend.delete(`/offices/${officeId}/closures/${closureDate}`);

export const removeClosureCommissions = async ({
  officeId,
  closureDate,
  origin,
  commissionType,
}: {
  officeId: number;
  closureDate: string;
  origin?: string;
  commissionType?: CommissionTypes;
}): Promise<void> => {
  const params: { commission_type?: number; origin?: string } = {};
  if (origin) params.origin = origin;
  if (commissionType) params.commission_type = commissionType;
  Remote.delete(`/offices/${officeId}/closures/${closureDate}/commissions`, { params });
};

export const getCommissionsByOrigin = async ({
  officeId,
  origin,
  commissionDate,
}: {
  officeId: number;
  origin: string;
  commissionDate: string;
}): Promise<CommissionResponse[]> => {
  const { data } = await Remote.get(
    `/offices/${officeId}/closures/${commissionDate}/${origin}/commissions`,
  );
  return data;
};

export const putCommissionsByDate = async ({
  officeId,
  commissionDate,
  commissions,
}: {
  officeId: number;
  commissionDate: string;
  commissions: InsertCommissionRequest[];
}): Promise<CommissionResponse[]> => {
  const { data } = await Remote.put(
    `/offices/${officeId}/preferences/${commissionDate}/${PreferencesType.ADJUSTMENTS}`,
    commissions,
  );
  return data;
};

export const putRecurrentRevenue = async ({
  officeId,
  commissionDate,
  recurrentRevenue,
}: {
  officeId: number;
  commissionDate: string;
  recurrentRevenue: SalaryPreference[];
}): Promise<CommissionResponse[]> => {
  const { data } = await ClosureBackend.put(
    `/offices/${officeId}/preferences/${commissionDate}/${PreferencesType.SALARY}`,
    recurrentRevenue,
  );
  return data;
};

export const getCommissionDivisions = async ({
  officeId,
  commissionId,
  impersonatedUser,
}: {
  officeId: number;
  commissionId: number;
  impersonatedUser: string;
}): Promise<DivisionSiblingsResponse> => {
  const { data } = await Remote.get(`/offices/${officeId}/commissions/${commissionId}/divisions`, {
    params: { creditor_id: impersonatedUser },
  });
  return data;
};

export const getCreditorPaycheck = async ({
  officeId,
  closureDate,
  externalCreditorId,
}: {
  officeId: number;
  closureDate: string;
  externalCreditorId: string;
}): Promise<PaycheckResponse> => {
  const { data } = await Remote.get(
    `/offices/${officeId}/creditor/${encodeURIComponent(externalCreditorId)}/paycheck`,
    {
      params: {
        closure_date: closureDate,
      },
    },
  );
  return data;
};

export const refundCompany = async ({ officeId, companyId }) => {
  const { data } = await Remote.post(`/offices/${officeId}/companies/${companyId}/refund`);
  return data;
};

export const patchLeaderAjustments = async ({
  officeId,
  closureDate,
  adjustments,
}: {
  officeId: number;
  closureDate: string;
  adjustments: CreateAdjustmentRequest[];
}) => {
  await Remote.patch(
    `/offices/${officeId}/preferences/${closureDate}/${PreferencesType.ADJUSTMENT_REQUESTS}`,
    adjustments,
  );
};

export const fetchCreditorAdjustmentsRequests = async ({
  officeId,
  closureDate,
  externalCreditorId,
}: {
  officeId: number;
  closureDate: string;
  externalCreditorId: string;
}): Promise<AdjustmentApprovalRequest[]> => {
  const { data } = await Remote.get(
    `/offices/${officeId}/preferences/${closureDate}/${
      PreferencesType.ADJUSTMENT_REQUESTS
    }/creditors/${encodeURIComponent(externalCreditorId)}`,
  );
  return data;
};
export const fetchAllAdjustmentsRequests = async ({
  officeId,
  closureDate,
}: {
  officeId: number;
  closureDate: string;
}): Promise<AdjustmentApprovalRequest[]> => {
  const { data } = await Remote.get(
    `/offices/${officeId}/preferences/${closureDate}/${PreferencesType.ADJUSTMENT_REQUESTS}`,
  );
  return data;
};

export const applyAdjustmentsRequest = async ({
  officeId,
  closureDate,
  adjustments,
}: {
  officeId: number;
  closureDate: string;
  adjustments: AdjustmentApprovalRequest[];
}): Promise<void> => {
  const { data } = await Remote.post(
    `/offices/${officeId}/preferences/${closureDate}/${PreferencesType.ADJUSTMENT_REQUESTS}/from-request`,
    adjustments,
  );
  return data;
};

export const sendSheetUrl = async ({
  officeId,
  dataSourceRequest,
}: {
  officeId: string;
  dataSourceRequest: DataSourceRequest;
}) => {
  const { data } = await ClosureBackend.post(`/offices/${officeId}/data-source`, dataSourceRequest);

  return data;
};
