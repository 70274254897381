import * as React from 'react';
import { useTheme } from '@material-ui/core';
import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const SingleReportIcon: React.FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 20h-1V5a1 1 0 0 0-2 0v15h-2v-2a1 1 0 0 0-2 0v2h-2v-5a1 1 0 0 0-2 0v5H8v-1a1 1 0 1 0-2 0v1H4V3a1 1 0 0 0-2 0v18a1 1 0 0 0 1 1h18a1 1 0 0 0 0-2Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.856 9.357a3 3 0 1 0-3.712 0 5.017 5.017 0 0 0-1.68 1.107A5 5 0 0 0 6 14a1 1 0 1 0 2 0 3 3 0 0 1 6 0 1 1 0 1 0 2 0 5 5 0 0 0-3.144-4.643ZM12 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
