import {
  CreditorPaymentRequest,
  CreditorPaymentResponse,
  GetPaymentsResponse,
  PaymentErrors,
  PaymentSuccess,
} from 'shared-types';
import { isAxiosError } from 'utils/error';
import Backend from '.';

export const getCompanyBalance = async ({
  officeId,
  companyId,
}: {
  officeId: number;
  companyId: number;
}): Promise<{ balance: number }> => {
  const { data } = await Backend.get<{ balance: number }>(
    `/offices/${officeId}/companies/${companyId}/balance`,
  );
  return data;
};

export const payCreditorsPerCompany = async ({
  officeId,
  closureDate,
  paymentList,
}: {
  officeId: number;
  closureDate: string;
  paymentList: CreditorPaymentRequest[];
}): Promise<CreditorPaymentResponse[]> => {
  const { data } = await Backend.post<CreditorPaymentResponse[]>(
    `/offices/${officeId}/closures/${closureDate}/payment`,
    paymentList,
  );

  return data;
};

function getFilenameFromContentDisposition(headerVal: string): string | undefined {
  if (!headerVal.includes('filename')) return undefined;
  const split = headerVal.split('filename=');
  return split[1];
}

const convertArrayBufferToJson = <R = PaymentSuccess | PaymentErrors>(
  buff: ArrayBuffer,
  isJson: boolean,
): R => {
  const data = new TextDecoder('utf-8').decode(buff);
  return isJson ? JSON.parse(data) : data;
};

const contentTypeIsJson = (contentType: string): boolean => {
  return contentType.includes('application/json');
};

export const payCreditorsPerCompanyLambda = async ({
  officeId,
  closureDate,
  paymentList,
}: {
  officeId: number;
  closureDate: string;
  paymentList: CreditorPaymentRequest[];
}): Promise<{ file: Blob | null; fileName: string | undefined; data?: PaymentSuccess }> => {
  try {
    const res = await Backend.post<ArrayBuffer>(
      `/offices/${officeId}/closures/${closureDate}/payment`,
      paymentList,
      { responseType: 'arraybuffer' },
    );
    const isJson = contentTypeIsJson(res.headers['content-type']);
    if (isJson) {
      const data = convertArrayBufferToJson<PaymentSuccess>(res.data, isJson);
      return { file: null, fileName: undefined, data };
    }

    return {
      file: new Blob([res.data]),
      fileName: getFilenameFromContentDisposition(res.headers['content-disposition']),
    };
  } catch (error) {
    if (isAxiosError(error) && error?.response?.data) {
      const isJson = contentTypeIsJson(error.response.headers['content-type']);
      error.response.data = convertArrayBufferToJson<PaymentErrors>(error?.response?.data, isJson);
    }

    throw error;
  }
};

export const getPayments = async (
  officeId: number,
  closureDate: string,
): Promise<GetPaymentsResponse[]> => {
  const { data } = await Backend.get(`/offices/${officeId}/closures/${closureDate}/payment`);
  return data;
};
