import React, { useCallback, useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from 'makeStyles';
import { Skeleton } from 'components/Loading/Skeleton';
import { useGetUser } from 'service/user';
import useSelector from 'modules/default-selector';
import { useHistory } from 'react-router-dom';
import { toggleCollapsedMenu } from 'modules/user';
import { useDispatch } from 'react-redux';
import Header from './header';
import Footer from './footer';
import MenuItem from './MenuItem';
import { BodyWrapper, makeClasses, CustomListItem } from './styles';
import { useMenuItems } from './hooks';
import { shouldOverlayRoute } from './utils';

const MenuLeft = () => {
  const { data: user } = useGetUser();
  const { classes } = makeStyles()(makeClasses)();
  const menuItems = useMenuItems();
  const menuOpenedPrefs = useSelector(state => state.user.preferences.menuOpened) ?? true;
  const dispatch = useDispatch();
  const history = useHistory();
  const isOverlayRoute = shouldOverlayRoute(history.location.pathname);
  const [menuOpened, setMenuOpened] = React.useState(false);
  const isMenuOpen = isOverlayRoute ? menuOpened : menuOpenedPrefs;

  useEffect(() => {
    const unlisten = history.listen(location => {
      if (shouldOverlayRoute(location.pathname)) setMenuOpened(false);
    });
    return unlisten;
  }, [history]);

  const handleToggleCollapseMenu = useCallback(() => {
    if (isOverlayRoute) {
      setMenuOpened(prev => !prev);
    } else dispatch(toggleCollapsedMenu(!isMenuOpen));
  }, [dispatch, isMenuOpen, isOverlayRoute, setMenuOpened]);

  return (
    <Drawer
      id="splitc-drawer"
      anchor="left"
      open={isMenuOpen}
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isMenuOpen,
        [classes.drawerClose]: !isMenuOpen,
        [classes.drawerHovered]: isOverlayRoute,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isMenuOpen,
          [classes.drawerClose]: !isMenuOpen,
          [classes.drawerHovered]: isOverlayRoute,
          [classes.paper]: true,
        }),
      }}
    >
      <Header toggleCollapseMenu={handleToggleCollapseMenu} isOpened={isMenuOpen} />
      <BodyWrapper>
        {user
          ? menuItems.map(menuItem => (
              <MenuItem key={menuItem.title} isOpened={isMenuOpen} item={menuItem} />
            ))
          : Array.from({ length: 9 }).map(() => (
              <CustomListItem button key={`skeleton-${Math.random()}`}>
                <Skeleton height="10px" width="100%" padding={16} />
              </CustomListItem>
            ))}
      </BodyWrapper>
      <Footer isOpened={isMenuOpen} />
    </Drawer>
  );
};

export default MenuLeft;
