import * as React from 'react';
import { DefaultIconType } from 'assets/icons/types';
import { useTheme } from '@material-ui/core';
import { iconBorderColor, iconFillColor } from 'theme';

export const VariableIcon: React.FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 6a3 3 0 0 1 3-3 1 1 0 0 1 0 2 1 1 0 0 0-1 1v3.938c0 .789-.307 1.519-.82 2.062.513.543.82 1.273.82 2.062V18a1 1 0 0 0 1 1 1 1 0 0 1 0 2 3 3 0 0 1-3-3v-3.938a1 1 0 0 0-.757-.97l-.486-.122a1 1 0 0 1 0-1.94l.486-.121A1 1 0 0 0 3 9.939V6Zm18 0a3 3 0 0 0-3-3 1 1 0 1 0 0 2 1 1 0 0 1 1 1v3.938c0 .789.307 1.519.82 2.062a2.997 2.997 0 0 0-.82 2.062V18a1 1 0 0 1-1 1 1 1 0 0 0 0 2 3 3 0 0 0 3-3v-3.938a1 1 0 0 1 .758-.97l.485-.122a1 1 0 0 0 0-1.94l-.485-.121a1 1 0 0 1-.758-.97V6Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path
        d="M7.091 15.639a.94.94 0 0 0-.007.786.905.905 0 0 0 .23.308c.19.174.436.269.69.266.272.01.54-.072.764-.234.24-.168.49-.37.749-.61.27-.251.511-.535.72-.844.22-.322.435-.64.646-.954.196-.29.376-.593.538-.905l.39-.75c.095.268.166.545.21.828.062.355.127.71.194 1.063.065.34.141.679.227 1.014.056.25.141.492.253.72.114.17.246.327.391.468.148.139.341.212.54.204.219.01.464-.088.735-.296.278-.218.538-.458.78-.72.243-.264.468-.546.673-.844.164-.228.305-.474.42-.734l-.359-.188c-.061.136-.14.282-.24.436a4.224 4.224 0 0 1-.825.94.696.696 0 0 1-.405.172.344.344 0 0 1-.24-.158 1.353 1.353 0 0 1-.18-.342 3.227 3.227 0 0 1-.179-.688l-.856-3.703c.289-.504.6-.994.931-1.468.176-.256.377-.491.599-.704.211-.196.409-.296.6-.296.193-.001.383.048.553.142.172.094.363.142.557.14.54 0 .81-.282.81-.846 0-.29-.1-.504-.301-.64A1.182 1.182 0 0 0 16.025 7c-.362.025-.71.155-1.006.374-.318.23-.625.516-.913.86a8.957 8.957 0 0 0-.764 1.046c-.22.356-.407.646-.557.876-.079-.324-.153-.62-.224-.89a7.118 7.118 0 0 0-.256-.798A4.722 4.722 0 0 0 11.421 7l-2.757.516v.39c.208-.043.419-.064.63-.062.245.011.487.059.72.14.197.078.373.207.51.374a2.1 2.1 0 0 1 .36.626c.1.276.185.557.254.844.08.35.165.699.256 1.046.09.347.176.697.255 1.048-.05.134-.136.312-.255.532l-.434.78c-.173.302-.357.598-.557.89-.2.291-.393.567-.585.827-.188.262-.365.47-.524.626-.16.154-.29.234-.39.234a.332.332 0 0 1-.224-.094 6.958 6.958 0 0 0-.24-.202 2.031 2.031 0 0 0-.301-.204.747.747 0 0 0-.773.024.792.792 0 0 0-.275.304Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
