import { createAction, createReducer } from 'redux-act';

export interface DataSourcesState {
  isSyncing: boolean;
  correlationId: string;
}

const defaultState: DataSourcesState = {
  isSyncing: false,
  correlationId: '',
};

const reducer = createReducer({}, defaultState);

export const setIsSyncingDataSource = createAction<boolean>('dataSource/IS_SYNCING');
export const setDatasourceCorrelationId = createAction<string>(
  'dataSource/SET_DATASOURCE_CORRELATION_ID',
);

reducer.on(setIsSyncingDataSource, (state, payload) => ({
  ...state,
  isSyncing: payload,
}));

reducer.on(setDatasourceCorrelationId, (state, payload) => ({
  ...state,
  correlationId: payload,
}));

export default reducer;
