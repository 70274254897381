import { useContext, useEffect } from 'react';
import { useGetUser } from 'service/user';
import { getTheme } from 'theme/getTheme';
import { ThemeContext, ThemeContextProps } from 'theme/themeContext';

/**
 * @description This custom hook update the global theme to use the user custom colors and tokens
 */
export function useUpdateTheme() {
  const { data: user } = useGetUser();

  const { setThemeOptions } = useContext<ThemeContextProps>(ThemeContext);

  useEffect(() => {
    const officeTheme = getTheme(user);

    setThemeOptions(officeTheme);
  }, [setThemeOptions, user]);
}
