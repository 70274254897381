import {
  UserData,
  UserUpdateRequest,
  UserCreateRequest,
  CurrentUserResponse,
  SimpleUser,
  TeamsResponse,
  User,
  PermissionData,
} from 'shared-types';
import { getItem, storeItem } from './storage.service';
import remote from '.';

export const setUserInfo = (userInfo: string) => {
  storeItem('user_info', userInfo);
};

export const getUserInfo = (): string | null => {
  return getItem('user_info') || null;
};

export const getLocalUserData = (): UserData | null => {
  const userInfo: any = getUserInfo();
  if (!userInfo) return null;
  const userInfoObject = JSON.parse(userInfo);
  return {
    id_escritorio: userInfoObject.id_escritorio,
    id_credor_externo: userInfoObject.id_credor_externo,
    email: userInfoObject.email,
  };
};

export const getRemoteUser = async (): Promise<CurrentUserResponse> => {
  const { data } = await remote.get<CurrentUserResponse>('/user');
  return data;
};

export const getRemoteUserList = async (office): Promise<SimpleUser[]> => {
  const { data } = await remote.get<SimpleUser[]>(`/office/${office}/users`);
  return data;
};

export const createRemoteUser = async ({
  user,
  officeId,
}: {
  user: UserCreateRequest;
  officeId: number | undefined;
}) => {
  const { data } = await remote.post(`/user/office/${officeId}`, user);
  return data;
};

export const editUser = async ({
  officeId,
  payload,
}: {
  officeId: number | undefined;
  payload: UserUpdateRequest;
}) => {
  if (!payload?.external_creditor_id) return null;
  const { data } = await remote.patch(
    `/user/office/${officeId}/${encodeURIComponent(payload.external_creditor_id)}`,
    payload,
  );
  return data;
};

export const getOfficeTeams = async (office: number): Promise<TeamsResponse[]> => {
  const url = `/offices/${office}/teams`;
  const { data } = await remote.get<TeamsResponse[]>(url);
  return data;
};

export const getUser = async ({
  officeId,
  externalCreditorId,
}: {
  officeId: number | undefined;
  externalCreditorId: string | undefined;
}): Promise<User> => {
  const url = `/office/${officeId}/users/${externalCreditorId}`;
  const { data } = await remote.get(url);
  return data;
};

export const permissionsUpdate = async ({
  officeId,
  externalCreditorId,
  claims,
}: {
  officeId: number | undefined;
  externalCreditorId: string | undefined;
  claims: PermissionData[];
}) => {
  const url = `/office/${officeId}/users/${encodeURIComponent(String(externalCreditorId))}/claims`;
  const { data } = await remote.put(url, claims);
  return data;
};
