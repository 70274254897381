import { ThemeOptions } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';
import { Size, SplitcPadding, SplitcPalete, SplitcTheme, darkColors, lightColors } from './tokens';

declare module '@material-ui/core/styles' {
  interface ThemeOptions extends SplitcTheme {}
  interface Theme extends SplitcTheme {}
}

/** Receives an string Array with 5 elements => Array[5]  */
const createSize = (values: string[]): Size => {
  return {
    extra_large: values[5],
    large: values[4],
    medium: values[3],
    small: values[2],
    extra_small: values[1],
    double_extra_small: values[0],
  };
};

const generateThemePadding = (): SplitcPadding => {
  return {
    container: createSize(['4px', '8px', '16px', '32px', '64px', '80px']),
    text: {
      title: createSize(['4px', '8px', '16px', '32px', '64px', '80px']),
      paragraph: createSize(['4px', '8px', '16px', '32px', '64px', '80px']),
      subparagraph: createSize(['4px', '8px', '16px', '32px', '64px', '80px']),
      support: '8px',
      continuos: '8px',
    },
  };
};

const generateThemeOpts = (colors: SplitcPalete): ThemeOptions => ({
  palette: colors.palete,
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  splitcPalette: colors,
  splitcPadding: generateThemePadding(),
});

export const darkTheme = generateThemeOpts(darkColors);

export const lightTheme = generateThemeOpts(lightColors);

export const iconFillColor = (
  palette: Palette,
  splitcPalette: SplitcPalete,
  active?: boolean,
  color?: string,
) => (active ? palette.primary.main : color ?? splitcPalette.neutral[5]);

export const iconBorderColor = (splitcPalette: SplitcPalete, active: boolean, color?: string) =>
  active ? splitcPalette.neutral[1] : color ?? splitcPalette.neutral[5];

export const splitcOrange = '#F87F06';
