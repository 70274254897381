import { SimpleSelectItem } from 'components/SimpleSelect';
import { ClaimInfo, PermissionType, SimplePermissionProfileResponse } from 'shared-types';

export type Tab = 'permissions' | 'creditorsAndTeams';

export type ClaimValue = string | string[] | SimpleSelectItem[] | boolean | Record<string, unknown>;

export interface Claim extends ClaimInfo {
  name: string;
  value: ClaimValue;
  profile_id?: number;
  permission_type?: PermissionType;
  isNew: boolean;
}

export enum PermissionProfileQueries {
  PERMISSION_PROFILES = 'permission_profiles',
  ALL_TEAMS = 'all-teams',
  ALL_CREDITORS = 'all-creditors',
  PERMISSION_PROFILE = 'permission-profile',
  PERMISSION_PROFILE_TEAMS_AND_CREDITORS = 'permission-profile-teams-and-creditors',
  GET_PERMISSION_PROFILES = 'get-permission-profiles',
  GET_PLAN_GROUP_PERMISSIONS = 'get-plan-group-permissions',
}

export type PermissionProfileState = {
  permissionProfiles: SimplePermissionProfileResponse[];
};

export interface ParamsProps {
  id: string;
}

export enum DeleteModalType {
  WITH_COMMISSION = 'WITH_COMMISSION',
  WITHOUT_COMMISSION = 'WITHOUT_COMMISSION',
  LOADING = 'LOADING',
}

export interface RedirectUserState {
  searchUser: string;
  userIndexInTable: number;
  showOnlyActivesUsers: boolean;
}
