import * as React from 'react';
import { useTheme } from '@material-ui/core';
import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const PaymentIcon: React.FC<DefaultIconType> = ({
  width = 24,
  height = 24,
  active = false,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm0 20a9 9 0 1 1 0-18 9 9 0 0 1 0 18Z"
        fill={iconBorderColor(splitcPalette, active)}
      />
      <path
        d="M15 9h-4a1 1 0 1 0 0 2h2a3 3 0 0 1 0 6v1a1 1 0 0 1-2 0v-1H9a1 1 0 0 1 0-2h4a1 1 0 0 0 0-2h-2a3 3 0 0 1 0-6V6a1 1 0 0 1 2 0v1h2a1 1 0 1 1 0 2Z"
        fill={iconFillColor(palette, splitcPalette, active)}
      />
    </svg>
  );
};
