export const defaultOpts = {
  isStopped: false,
  isPaused: false,
  speed: 0.9,
  direction: 1,
  width: 500,
  height: 500,

  options: {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
};
