import Backend from '.';

export const getCreditorIframeUrl = async (): Promise<string> => {
  const endpoint = `/dashboards/creditor`;

  const response = await Backend.get<string>(endpoint);

  return response.data;
};

export const getOfficeIframeUrl = async (): Promise<string> => {
  const endpoint = `/dashboards/office`;

  const response = await Backend.get<string>(endpoint);

  return response.data;
};

export const getMonthlyManagementIframeUrl = async (): Promise<string> => {
  const endpoint = `/dashboards/management`;

  const response = await Backend.get<string>(endpoint);

  return response.data;
};

export const getTeamsIframeUrl = async (): Promise<string> => {
  const endpoint = `/dashboards/teams`;
  const response = await Backend.get<string>(endpoint);
  return response.data;
};

export const getCustomReports = async ({ questionId }: { questionId?: number }) => {
  const endpoint = `/dashboards/custom/${questionId}`;
  const response = await Backend.get<string>(endpoint);
  return response.data;
};
