import styled from 'styled-components';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuList,
  Theme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CSSObject } from 'tss-react';

type PropsLogo = {
  $isOpened: boolean;
  $active?: boolean;
};

export const DRAWER_OPEN_WIDTH = '18rem';
export const DRAWER_CLOSED_WIDTH = '4.5rem';

export const Wrapper = styled(Grid)<PropsLogo>`
  padding: 1.5rem 0.5rem 0;
`;

export const CustomListItem = styled(ListItem)`
  border-radius: 0.5rem;
  margin-bottom: 2px;
  .MuiListItemIcon-root {
    min-width: 2.5rem;
  }
`;

export const EndIcon = styled(ListItemIcon)`
  display: flex;
  justify-content: flex-end;
`;
export const CustomMenuList = styled(MenuList)`
  padding: 0;
`;

export const CustomListItemIcon = styled(ListItemIcon)``;

export const CustomListText = styled(ListItemText)`
  overflow: hidden;
  color: ${props => props.theme.splitcPalette.neutral[4]};
`;

export const BodyWrapper = styled(List)`
  padding: 8px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 70vh;
`;

export const LogoContainer = styled(Grid)`
  display: flex;
  height: 4rem;
  align-items: center;
`;

export const WrapperUser = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-transform: initial;
`;

export const Text = styled.p`
  width: 136px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: ${props => props.theme.splitcPalette.neutral[1]};
  margin-left: 8px;
  text-transform: initial;
  text-align: left;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.splitcPalette.neutral[9]};
`;

export const WrapperIcon = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  width: 1.6rem;
  height: 1.6rem;
  background-color: ${props => props.theme.splitcPalette.neutral[9]};
  border-radius: 50%;
  cursor: pointer;
  color: ${props => props.theme.splitcPalette.neutral[4]};
  &:hover {
    background-color: ${props => props.theme.splitcPalette.neutral[5]};
    color: ${props => props.theme.splitcPalette.neutral[1]};
  }
`;

export const WrapperLine = styled(Grid)`
  margin-top: 16px;
`;

export const TextSplitc = styled.a`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 16px;
  text-decoration: none;
  color: #f57f17;
`;

export const ItemDropDown = styled.div`
  background: ${props => props.theme.splitcPalette.neutral[9]};
  color: #ababab;
  width: 250px;
  border: 1px solid #525252;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0.6rem 1rem;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.splitcPalette.neutral[10]};
    color: ${props => props.theme.splitcPalette.neutral[1]};
    border: 1px solid ${props => props.theme.splitcPalette.neutral[10]};
  }
`;

export const TextDropDown = styled.p`
  margin: 0;
  padding-left: 10px;
`;

export const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    background-color: transparent;
  }
  .MuiList-root {
    padding: 0;
    border-radius: 16px;
  }
`;

export const IconChangeOffice = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${props => props.theme.splitcPalette.neutral[5]};
    color: ${props => props.theme.splitcPalette.neutral[1]};
  }
`;

export const TextNameOffice = styled.p`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 500;
`;

export const RawLink = styled.a`
  text-decoration: none !important;
  color: inherit;
  display: flex;
`;

export const makeClasses = (theme: Theme): Record<string, CSSObject> => ({
  paper: {
    backgroundColor: theme.splitcPalette.neutral[11],
    overflow: 'hidden',
  },
  drawer: {
    width: DRAWER_CLOSED_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  drawerOpen: {
    width: DRAWER_OPEN_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerHovered: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
  },
  drawerClose: {
    width: DRAWER_CLOSED_WIDTH,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});
