import { useEffect } from 'react';
import { errorStore } from 'stores/errorStore';
import { showAlertModal, unexpectedError } from 'modules/ui';
import ErrorBoundary from 'components/ErrorBoundary';
import GlobalStyle from 'theme/GlobalStyles';
import { dispatch } from 'redux-store';
import { ClerkProvider } from '@clerk/clerk-react';
import Error from 'pages/SSO/animations/Error';
import { setupErrorHandler } from 'utils/error';
import AllProviders from './Providers';
import Routes from './pages/routes';

import 'jspreadsheet/dist/jspreadsheet.css';
import 'jspreadsheet/dist/jspreadsheet.themes.css';
import 'jsuites/dist/jsuites.css';

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

const App = () => {
  useEffect(() => {
    const handleNetworkError = () => {
      dispatch(
        showAlertModal({
          heading: 'Ops',
          body: 'Houve alguma falha na conexão com a internet',
        }),
      );
    };
    const defaultHandler = (error: Error) => dispatch(unexpectedError(error));

    errorStore.setupErrorHandlers({
      handleNetworkError,
      defaultHandler,
      dispatch,
    });
    setupErrorHandler({ whenWithoutConnection: handleNetworkError, whenUnknown: defaultHandler });
  }, []);

  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY ?? ''}>
      <AllProviders>
        <GlobalStyle />
        <ErrorBoundary fallback={<Error />}>
          <div className="App">
            <Routes />
          </div>
        </ErrorBoundary>
      </AllProviders>
    </ClerkProvider>
  );
};

export default App;
