import { createAction, createReducer } from 'redux-act';
import {
  PreferencesResponseV2,
  NamedRule,
  BulkUpsertListsRequest,
  ReplacementGroups,
} from 'shared-types';
import { swap } from 'utils/array';

export const setPreferences = createAction<PreferencesResponseV2['rules']>(
  'commission/RULES_V2/SET_RULES',
);

export const newRule = createAction<string>('commission/RULES_V2/NEW_RULE');
export const newSubdivision = createAction<string>('commission/RULES_V2/NEW_SUBDIVISION');
export const setBulkList = createAction<BulkUpsertListsRequest[]>('commission/RULES_V2/BULK_LIST');
export const remove = createAction<number>('commission/RULES_V2/REMOVE');

export const changeOrder = createAction(
  'commission/RULES_V2/CHANGE_ORDER',
  (index: number, move: number) => ({ index, move }),
);

export const setNamedRule = createAction<{ order: number; data: any; name: string | undefined }>(
  'commission/RULES_V2/SET_RULES',
  (order: number, data: any, name?: string) => ({ order, data, name }),
);

export const setSubdivision = createAction<NamedRule>('commission/RULES_V2/SET_SUBDIVISION');

export const setReplacements = createAction<ReplacementGroups[]>('commission/RULES/REPLACEMENTS');

export interface RulesState {
  namedRules: PreferencesResponseV2['rules'];
  replacements: ReplacementGroups[];
  bulkLists: BulkUpsertListsRequest[];
  subDivisionRules: PreferencesResponseV2['rules'];
}

const customRule: NamedRule = {
  rule_type: 'Custom',
  name: 'Outras regras',
  changes: [],
};

export const defaultRules: PreferencesResponseV2['rules'] = [
  {
    rule_type: 'ChangeCustomerOwner',
    name: 'Troca de clientes',
    changes: [],
  },
  customRule,
];

const initialState: RulesState = {
  namedRules: defaultRules,
  replacements: [],
  bulkLists: [],
  subDivisionRules: [],
};

const reducerV2 = createReducer<RulesState>({}, initialState);

reducerV2.on(
  setPreferences,
  (state, payload): RulesState => ({
    ...state,
    namedRules: payload,
  }),
);

reducerV2.on(
  remove,
  (state, payload): RulesState => {
    const newRules = state.namedRules.filter((_, index) => {
      return index !== payload;
    });
    return {
      ...state,
      namedRules: newRules,
    };
  },
);

reducerV2.on(
  newRule,
  (state, payload): RulesState => {
    const ruleInsertedByTheCustomer = {
      ...customRule,
      name: payload,
    };

    return {
      ...state,
      namedRules: [...state.namedRules, ruleInsertedByTheCustomer],
    };
  },
);

reducerV2.on(
  setNamedRule,
  (state, { order, data, name }): RulesState => {
    const { namedRules } = state;
    namedRules[order] = { ...namedRules[order], ...data };
    if (name) namedRules[order].name = name;

    return {
      ...state,
      namedRules,
    };
  },
);

const getSubdivision = ({ rule, name }) => rule.rule_type === 'Subdivision' && rule.name === name;
reducerV2.on(
  setSubdivision,
  (state, payload): RulesState => {
    const { namedRules } = state;
    const ruleBeingChanged = namedRules.find(rule => {
      if (getSubdivision({ rule, name: payload.name })) {
        return rule;
      }
      return false;
    });
    if (!ruleBeingChanged) {
      return {
        ...state,
        namedRules: [...namedRules, payload],
      };
    }
    const rulesChanged = namedRules.map(rule => {
      if (getSubdivision({ rule, name: payload.name })) {
        return { ...rule, ...payload };
      }
      return rule;
    });

    return {
      ...state,
      namedRules: [...rulesChanged],
    };
  },
);

reducerV2.on(
  newSubdivision,
  (state, name): RulesState => {
    const ruleBeingChanged = state.namedRules.find(rule => {
      if (getSubdivision({ rule, name })) {
        return rule;
      }
      return false;
    });
    if (!ruleBeingChanged) {
      const ruleInsertedByTheCustomer: NamedRule = {
        changes: [],
        name,
        rule_type: 'Subdivision',
      };
      return {
        ...state,
        namedRules: [...state.namedRules, ruleInsertedByTheCustomer],
      };
    }

    return state;
  },
);

reducerV2.on(
  setReplacements,
  (state, payload): RulesState => ({
    ...state,
    replacements: payload,
  }),
);

reducerV2.on(
  setBulkList,
  (state, payload): RulesState => ({
    ...state,
    bulkLists: payload,
  }),
);

reducerV2.on(
  changeOrder,
  (state, { index, move }): RulesState => {
    const from = index;
    const to = index - move;

    const isTryingToGoUpperThanClientSwitch = to === 0;
    if (isTryingToGoUpperThanClientSwitch) return state;

    const { namedRules } = state;
    const newNamedRulesOrder = swap(namedRules, from, to);

    return {
      ...state,
      namedRules: [...newNamedRulesOrder],
    };
  },
);

export default reducerV2;
