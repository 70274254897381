import useSelector from 'modules/default-selector';
import React, { useEffect } from 'react';
import { ConfigSyncer } from 'webworkers';

export const SyncUserConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const currentPreferences = useSelector(state => state.user?.preferences);
  const impersonatedUser = useSelector(state => state.user?.impersonationUser?.externalCreditorId);
  const credor = useSelector(state => state.user.credor);
  const { dispatchWorkerMessage } = ConfigSyncer();

  useEffect(() => {
    // If the user is impersonated so the preferences are not related to the user
    if (!impersonatedUser && credor) {
      dispatchWorkerMessage?.({
        type: 'user_config_changed',
        newConfig: currentPreferences,
      });
    }
  }, [currentPreferences, impersonatedUser, credor]);

  return <>{children}</>;
};
