import { PermissionProfileQueries } from 'pages/Users/types';
import { queryClient, QueryPrefixes } from 'service/query.client';

const rulesApplyingSensitiveQueries = [
  QueryPrefixes.COMMISSION_DIVISIONS,
  QueryPrefixes.CREDITOR_CLOSURES,
  QueryPrefixes.CREDITOR_COMMISSIONS,
  QueryPrefixes.CREDITOR_PAYCHECK,
  QueryPrefixes.GET_ALL_CREDITORS_FROM_OFFICE,
  QueryPrefixes.CREDITOR_EARNED_AMOUNT,
  QueryPrefixes.GET_USER_LIST,
  QueryPrefixes.GET_TEAMS,
  PermissionProfileQueries.ALL_CREDITORS,
];

export const invalidateCreditorQueries = () =>
  rulesApplyingSensitiveQueries.forEach(prefix => queryClient.invalidateQueries(prefix));

export const invalidatePreferencesQueries = () =>
  queryClient.invalidateQueries(QueryPrefixes.GET_PREFERENCES);
