import styled from 'styled-components';
import { biggerThan } from 'utils/responsivity';

export { default as CenterText } from './CenterText';
export { default as LateralPadding } from './LateralPadding';
export { default as TextAlignRight } from './TextAlignRight';

export const MaterialTableStyled = styled.div`
  .MuiToolbar-root {
    padding-top: 17px;
    display: flex;
  }
  .custom_splitc_style > div > div {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  ${biggerThan('md')} {
    .MuiToolbar-root {
      padding-top: 0;
      display: flex;
    }

    .custom_splitc_style > div > div {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
  }
`;
