import { dispatch } from 'redux-store';
import useSelector from 'modules/default-selector';
import { Close } from '@material-ui/icons';
import { prettyLabelWithKey } from 'utils/text';
import { CustomTooltip } from 'components/CustomTooltip';
import { exitImpersonation, impersonationUser } from 'modules/user';
import { useEffect } from 'react';
import { getImpersonatedUser } from 'modules/user/impersonation';
import * as S from './styles';
import history from '../../pages/history';
import ROUTES from '../../pages/routes-enum';

export const ImpersonationHeader = () => {
  const impersonatedUser = useSelector(state => state.user.impersonationUser);

  useEffect(() => {
    const impUser = getImpersonatedUser();
    if (impUser) {
      dispatch(
        impersonationUser({
          externalCreditorId: impUser.externalCreditorId,
          name: impUser.name,
        }),
      );
    }
  }, []);

  const handleImpersonationExit = () => {
    dispatch(exitImpersonation());
    // make sure the window is reloaded AFTER the redux is updated
    setTimeout(() => {
      history.replace(ROUTES.ROOT);
      window.location.reload();
    }, 100);
  };

  return impersonatedUser ? (
    <S.ImpersonationHeader>
      Você está personificando o usuário
      <b>{prettyLabelWithKey(impersonatedUser.externalCreditorId, impersonatedUser.name)}.</b>
      <CustomTooltip title="Sair" enterDelay={300}>
        <S.CloseIconButton
          size="small"
          onClick={handleImpersonationExit}
          data-testid="exit-impersonation"
        >
          <Close />
        </S.CloseIconButton>
      </CustomTooltip>
    </S.ImpersonationHeader>
  ) : null;
};
