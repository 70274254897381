import React from 'react';
import * as Sentry from '@sentry/react';
import { UserData } from 'shared-types';
import { getItem } from 'service/storage.service';
import axios from 'axios';

export const { isAxiosError } = axios;

export const setUser = ({ name, email, id, ...rest }) => {
  Sentry.configureScope(scope => {
    scope.setUser({
      name,
      email,
      id,
    });
  });
  const userAttributesToLog = {
    creditor: rest?.credor,
    office: rest?.escritorio,
    impersonated: rest?.impersonatedUser,
  };
  if (rest) Sentry.setContext('user_custom_attributes', userAttributesToLog);
};

const addUserInfoFromLocalStorage = (scope: Sentry.Scope) => {
  if (!scope.getUser()?.email) {
    const userFromLocalStorage = getItem('user_info');
    const user: UserData = JSON.parse(userFromLocalStorage ?? '{}');
    scope.setUser({
      email: user?.email,
    });
    scope.setContext('local_user_data', { ...user });
  }
};

export const logMessage = (message: string, additionalData?: Record<string, unknown>) => {
  Sentry.withScope(scope => {
    addUserInfoFromLocalStorage(scope);
    if (additionalData) scope.setContext('extra_data', additionalData);
    Sentry.captureMessage(message);
  });
};

export const logError = (error: Error, componentTrace?: React.ErrorInfo) => {
  Sentry.withScope(scope => {
    if (!scope.getUser()?.email) {
      const userFromLocalStorage = getItem('user_info');
      const user: UserData = JSON.parse(userFromLocalStorage ?? '{}');
      scope.setUser({
        email: user?.email,
      });
      scope.setContext('local_user_data', { ...user });
      if (componentTrace?.componentStack) {
        scope.setContext('react_info', { stack: componentTrace.componentStack });
      }
    }
    Sentry.captureException(error, scope);
  });
};

export const extraErrorData = (key: string, data: { [key: string]: unknown }) =>
  Sentry.setContext(key, data);

let handleNetworkError = () => {};
let defaultHandler = (error: Error) => logError(error);

export const setupErrorHandler = ({ whenWithoutConnection, whenUnknown }) => {
  handleNetworkError = whenWithoutConnection;
  defaultHandler = whenUnknown;
};

interface CustomError extends Error {
  handled?: boolean;
}

/**
 * Sets the error as `handled` so that `handleDefaultErrors` is called it doesnt show any popups and doesnt log to sentry.
 */
export const setErrorAsHandled = (err: Error): CustomError => {
  const customError: CustomError = err;
  customError.handled = true;
  return err;
};

export const handleDefaultErrors = (error: CustomError | Error | unknown): void => {
  if (!error || (error as CustomError)?.handled) {
    return;
  }
  const err = error as Error;
  if (err?.message === 'Network Error') {
    handleNetworkError();
    return;
  }
  defaultHandler(err);
};
