import { PickByValue } from './utility-types';

export const swap = <T>(array: Array<T>, from: number, to: number): Array<T> => {
  const lastIndex = array.length - 1;

  if (from > lastIndex) return array;
  if (to > lastIndex) return array;
  if (to < 0) return array;
  if (from < 0) return array;

  const arrayCopy = array;
  const itemCopy = arrayCopy[from];
  arrayCopy[from] = arrayCopy[to];
  arrayCopy[to] = itemCopy;
  return arrayCopy;
};

export const unique = <T>(values: T[]): T[] => Array.from(new Set(values));

export const clearStringProperty = <T>(arr: T[], prop: keyof PickByValue<T, string>) => {
  return arr.map(obj => ({ ...obj, [prop]: '' }));
};

export const insertInIndex = <T>(array: Array<T>, index: number, newItem: T): Array<T> => {
  return [...array.slice(0, index), newItem, ...array.slice(index)];
};
