import { ThemeOptions } from '@material-ui/core';
import { CurrentUserResponse, UsersClaims } from 'shared-types';
import { darkTheme, lightTheme } from 'theme';

const baseTheme = {
  light: lightTheme,
  dark: darkTheme,
};

/**
 * This function receives the user claims and set the theme based on theme.config claim
 * @param user - The current user logged
 * @returns - A custom theme or the dark theme as fallback
 */
export const getTheme = (user?: CurrentUserResponse): ThemeOptions => {
  const officeName = user?.escritorio?.nome_escritorio;

  const themePaletteConfig = user?.claims?.find(p => p.atributo === UsersClaims.THEME_CONFIG);

  if (!themePaletteConfig && !officeName) return darkTheme;
  if (themePaletteConfig) {
    const themeConfig = JSON.parse(String(themePaletteConfig.valor)) as ThemeOptions;
    const { palette } = themeConfig;

    const base = baseTheme[palette?.type ?? 'dark'];
    const themeOpt = {
      ...base,
      palette: {
        ...palette,
      },
    };
    return themeOpt;
  }
  return darkTheme;
};
