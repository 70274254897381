import { getItem } from 'service/storage.service';

export const IMPERSONATION_ITEM_KEY = 'sc::impersonating_user';

export interface ImpersonationUserPayload {
  name: string;
  externalCreditorId: string;
}

export function getImpersonatedUser() {
  try {
    const store = getItem(IMPERSONATION_ITEM_KEY);
    return store ? (JSON.parse(store) as ImpersonationUserPayload) : null;
  } catch {
    return null;
  }
}
