import { createAction, createReducer } from 'redux-act';

export interface CommissionPlanState {
  openEvolveCalcModal: boolean;
}

const defaultState: CommissionPlanState = {
  openEvolveCalcModal: false,
};

const reducer = createReducer({}, defaultState);

export const setOpenEvolveCalcModal = createAction<boolean>('commissionPlan/OPEN_EVOLVE_CALC');

reducer.on(setOpenEvolveCalcModal, (state, payload) => ({
  ...state,
  openEvolveCalcModal: payload,
}));

export default reducer;
