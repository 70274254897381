import styled from 'styled-components';
import { DRAWER_CLOSED_WIDTH, DRAWER_OPEN_WIDTH } from './MenuLeft/styles';

export const Right = styled.div<{
  $isDesktop: boolean;
  $isOpened: boolean;
  $isOverlay?: boolean;
  $hideMenu?: boolean;
}>`
  width: ${({ $isDesktop, $isOpened, $isOverlay, $hideMenu }) => {
    if (!$isDesktop || $hideMenu) return '100%';
    if ($isOverlay) return `calc(100% -  ${DRAWER_CLOSED_WIDTH})`;
    return `calc(100% - ${$isOpened ? DRAWER_OPEN_WIDTH : DRAWER_CLOSED_WIDTH})`;
  }};
  height: 100vh;
  background-color: ${props =>
    props.theme.palette.type === 'dark'
      ? props.theme.palette.common.black
      : props.theme.palette.background.default};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
