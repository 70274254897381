import { errorGenerator } from 'service/errorGenerator';
import axios from 'axios';
import { Dispatch } from 'redux';
import { showErrorModal } from 'modules/ui';
import { logError } from 'utils/error';

const { isAxiosError } = axios;

type Listener = () => void;

let listeners: Listener[] = [];
let currentError: unknown = null;

interface HandlersConfig {
  handleNetworkError: (error: Error) => void;
  defaultHandler: (error: Error) => void;
  dispatch: Dispatch<any> | null;
}

let handlersConfig: HandlersConfig = {
  handleNetworkError: () => {},
  defaultHandler: () => {},
  dispatch: null,
};

const emitChange = () => {
  for (const listener of listeners) {
    listener();
  }
};

export const errorStore = {
  errorSink: (sinkedError: unknown) => {
    if (!handlersConfig.dispatch) throw new Error('ErrorStore not initialized');

    const error = sinkedError as Error;
    if (isAxiosError(error) && error.response?.data) {
      handlersConfig.dispatch(showErrorModal(errorGenerator(error)));
      return;
    }

    if (error?.message === 'Network Error') {
      handlersConfig.handleNetworkError(error);
      return;
    }
    logError(error);
    errorStore.setError(error);
  },
  getError: () => {
    return currentError;
  },
  getHandlersConfig: () => {
    return handlersConfig;
  },
  setError: (newError: Error | null) => {
    currentError = newError;
    emitChange();
  },
  setupErrorHandlers: (newHandlersConfig: Partial<HandlersConfig>) => {
    handlersConfig = {
      ...handlersConfig,
      ...newHandlersConfig,
    };
    emitChange();
  },
  subscribe: (listener: Listener) => {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter(l => l !== listener);
    };
  },
};
