import useSelector from 'modules/default-selector';
import { UsersClaims } from 'shared-types/user';
import { Redirect, Route, RouteComponentProps, RouteProps, useHistory } from 'react-router-dom';
import { getAccessToken } from 'service/auth.service';
import { hasPermission } from 'modules/user.selector';
import { isDesktop } from 'utils/responsivity';
import { useSsoPref } from 'pages/SSO/hooks';
import { chooseSignRoute } from 'utils/url';
import ROUTES from '../../pages/routes-enum';
import { Right } from './styles';
import PrivateProviders from './private.providers';
import { shouldOverlayRoute } from './MenuLeft/utils';

interface Props extends RouteProps {
  claimsNeeded?: UsersClaims[];
}

const PrivateRoute = ({ component, claimsNeeded, ...rest }: Props) => {
  const menuOpen = useSelector(state => state.user?.preferences.menuOpened) ?? true;
  const hasNeededClaim = useSelector(hasPermission(claimsNeeded));
  const {
    location: { pathname },
  } = useHistory();
  const desktop = isDesktop();
  const { ssoHideMenu } = useSsoPref();
  const Component = component as React.ComponentType<RouteComponentProps>;

  return (
    <PrivateProviders>
      <Right
        $isOpened={menuOpen}
        $hideMenu={ssoHideMenu}
        $isOverlay={shouldOverlayRoute(pathname)}
        $isDesktop={desktop}
      >
        <Route
          {...rest}
          render={props => {
            if (claimsNeeded && !hasNeededClaim) return <Redirect to={ROUTES.ROOT} />;

            if (getAccessToken()) return <Component {...props} />;

            return <Redirect to={chooseSignRoute()} />;
          }}
        />
      </Right>
    </PrivateProviders>
  );
};

export default PrivateRoute;
