import { createAction, createReducer } from 'redux-act';
import { ComissionsDetailResponsev2, ResultadoComissoes, PaycheckResponse } from 'shared-types';
import {
  removeClosureCommissions,
  deleteClosure,
  getAllCommissions,
  getCommissionDetails,
  getCreditorPaycheck,
} from 'service/commission.service';
import { CommissionTypes } from 'service/closure.service';
import { formatUtc } from 'utils/date';
import { handleDefaultErrors, isAxiosError } from 'utils/error';
import { DefaultThunk } from './typings';

export const deleteLoading = createAction<boolean>('commission/COMMISSION/LOADING_DELETION');
export const loading = createAction<boolean>('commission/COMMISSION/LOADING');
export const setSelectedTotal = createAction<number>('commission/COMMISSION/SET_SELECTED_TOTAL');
export const setIsSyncingCommissions = createAction<boolean>('commission/COMMISSION/IS_SYNCING');
export const setCommissionPlanCorrelationId = createAction<string>(
  'commission/COMMISSION/SET_COMMISSION_PLAN_CORRELATION_ID',
);
export const setIsSyncingClosures = createAction<boolean>(
  'commission/COMMISSION/IS_SYNCING_CLOSURES',
);

export const setSelectedDate = createAction<Date, { date: string }>(
  'commission/COMMISSION/SET_SELECTED_DATE',
  date => ({ date: formatUtc(date) }),
);
const setClosureList = createAction<ResultadoComissoes[] | null>(
  'commission/COMMISSION/CLOSURE_LIST',
);
const setSelectedCommissions = createAction<ComissionsDetailResponsev2 | null>(
  'commission/COMMISSION/SELECTED',
);
const setPaycheckData = createAction<PaycheckResponse | null>(
  'commission/COMMISSION/PAYCHECK/REQUESTED',
);

export interface CommissionState {
  deleteLoading: boolean;
  loading: boolean;
  selectedTotal: number;
  selectedDate?: string;
  closureList: ResultadoComissoes[] | null;
  commissions: ComissionsDetailResponsev2 | null;
  paycheck: PaycheckResponse | null;
  isSyncing: boolean;
  correlationId: string;
  isSyncingClosures: boolean;
}

const initialState: CommissionState = {
  loading: true,
  deleteLoading: false,
  selectedTotal: 0.0,
  closureList: [],
  commissions: null,
  paycheck: null,
  isSyncing: false,
  correlationId: '',
  isSyncingClosures: false,
};

const reducer = createReducer<CommissionState>({}, initialState);

reducer.on(
  loading,
  (state, payload): CommissionState => ({
    ...state,
    loading: payload,
  }),
);
reducer.on(
  deleteLoading,
  (state, payload): CommissionState => ({
    ...state,
    deleteLoading: payload,
  }),
);

reducer.on(
  setSelectedCommissions,
  (state, payload): CommissionState => ({
    ...state,
    commissions: payload,
  }),
);

reducer.on(
  setSelectedTotal,
  (state, payload): CommissionState => ({
    ...state,
    selectedTotal: payload,
  }),
);

reducer.on(
  setClosureList,
  (state, payload): CommissionState => ({
    ...state,
    closureList: payload,
  }),
);

reducer.on(
  setSelectedDate,
  (state, payload): CommissionState => ({
    ...state,
    selectedDate: payload.date,
  }),
);

reducer.on(
  setPaycheckData,
  (state, payload): CommissionState => ({
    ...state,
    paycheck: payload,
  }),
);

reducer.on(
  setIsSyncingCommissions,
  (state, payload): CommissionState => ({
    ...state,
    isSyncing: payload,
  }),
);

reducer.on(
  setCommissionPlanCorrelationId,
  (state, payload): CommissionState => ({
    ...state,
    correlationId: payload,
  }),
);

reducer.on(
  setIsSyncingClosures,
  (state, payload): CommissionState => ({
    ...state,
    isSyncingClosures: payload,
  }),
);

export const fetchCommissions = (commissionDate?: string): DefaultThunk => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { impersonatedUser } = state.user;

  const loadDate = commissionDate || state.commission.selectedDate;

  dispatch(loading(true));

  try {
    if (!impersonatedUser?.officeId || !impersonatedUser?.creditorId)
      throw new Error(
        'Tried to fetchCommissions() without state.user.impersonatedUser.officeId or creditorId',
      );
    if (!loadDate)
      throw new Error(
        'Couldnt determine loadDate on fetchCommissions(). state.commission.selectedDate was null',
      );

    const data = await getCommissionDetails({
      officeId: impersonatedUser.officeId,
      creditor: impersonatedUser.creditorId,
      commissionDate: loadDate,
    });

    dispatch(loading(false));
    dispatch(setSelectedCommissions(data));
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const chooseClosure = (selectedDate: string): DefaultThunk => (dispatch, getState) => {
  const { closureList } = getState().commission;
  const earning = closureList?.find(closure => closure.data_fechamento === selectedDate);

  dispatch(setSelectedTotal(earning?.resultado || 0.0));
  dispatch(setSelectedDate(new Date(selectedDate)));
  dispatch(fetchCommissions(selectedDate));
};

const handleError = (error: Error): DefaultThunk => dispatch => {
  if (error.message === 'WITHOUT_COMMISSIONS') {
    dispatch(setClosureList(null));
    dispatch(setSelectedCommissions(null));
    dispatch(setSelectedTotal(0));
    dispatch(loading(false));
    return;
  }
  handleDefaultErrors(error);
};

export const fetchCreditorsLastResult = (): DefaultThunk => async (dispatch, getState) => {
  const state = getState();
  const { creditorId, officeId } = state.user.impersonatedUser || {};
  try {
    dispatch(loading(true));
    if (!officeId)
      throw new Error(
        'Tried to fetchCreditorsLastResult() without state.user.impersonatedUser.officeId',
      );
    if (!creditorId)
      throw new Error(
        'Tried to fetchCreditorsLastResult() without state.user.impersonatedUser.creditorId',
      );

    const creditorsCommissions = await getAllCommissions(officeId, creditorId);
    if (!creditorsCommissions?.resultados || creditorsCommissions.resultados?.length === 0) {
      throw new Error('WITHOUT_COMMISSIONS');
    }

    dispatch(setClosureList(creditorsCommissions.resultados));

    const mostRecentCommisionIndex = creditorsCommissions.resultados.length - 1;
    const mostRecentCommission = creditorsCommissions.resultados[mostRecentCommisionIndex];
    dispatch(chooseClosure(mostRecentCommission.data_fechamento));
  } catch (e) {
    dispatch(handleError(e as Error));
  }
};

export const fetchCreditorFixedMonthClosure = (): DefaultThunk => async (dispatch, getState) => {
  const state = getState();
  const { creditorId, officeId } = state.user.impersonatedUser || {};
  try {
    dispatch(loading(true));

    if (!officeId)
      throw new Error(
        'Tried to fetchCreditorFixedMonthClosure() without state.user.impersonatedUser.officeId',
      );
    if (!creditorId)
      throw new Error(
        'Tried to fetchCreditorFixedMonthClosure() without state.user.impersonatedUser.creditorId',
      );

    const creditorsCommissions = await getAllCommissions(officeId, creditorId);
    if (!creditorsCommissions?.resultados || creditorsCommissions.resultados?.length === 0) {
      throw new Error('WITHOUT_COMMISSIONS');
    }

    const hasCommissionsInSelectedMonth = creditorsCommissions.resultados?.find(
      closure => closure.data_fechamento === state.commission.selectedDate,
    );

    if (hasCommissionsInSelectedMonth) {
      if (!state.commission.selectedDate)
        throw new Error(
          'Cant choose a closure on fetchCreditorFixedMonthClosure() without state.commission.selectedDate',
        );
      dispatch(chooseClosure(state.commission.selectedDate));
    } else {
      dispatch(setClosureList(null));
      dispatch(setSelectedCommissions(null));
      dispatch(setSelectedTotal(0));
      dispatch(loading(false));
    }

    dispatch(setClosureList(creditorsCommissions.resultados));
  } catch (e) {
    dispatch(handleError(e as Error));
  }
};

export const deleteClosureCommissions = (
  shouldDeleteEntireClosure: boolean,
  origin?: string,
  afterDelete?: (deletedEntireClosure: boolean) => void,
): DefaultThunk => async (dispatch, getState) => {
  const state = getState();

  const closureDate = state.commission.selectedDate;
  const officeId = state?.user?.escritorio?.id_escritorio;
  if (!officeId) throw new Error('Tried to delete closure without office');
  if (!closureDate) throw new Error('Cant remove a closure without closureDate');

  try {
    dispatch(deleteLoading(true));
    if (shouldDeleteEntireClosure) {
      await deleteClosure({
        officeId,
        closureDate,
      });
      return;
    }
    await removeClosureCommissions({
      officeId,
      closureDate,
      origin,
      commissionType: CommissionTypes.COMMISSION,
    });
  } catch (error) {
    handleDefaultErrors(error);
  } finally {
    dispatch(deleteLoading(false));
    if (afterDelete) afterDelete(shouldDeleteEntireClosure);
  }
};

export const fetchCreditorPaycheck = (): DefaultThunk => async (dispatch, getState) => {
  const state = getState();
  const { creditorId, officeId } = state.user.impersonatedUser || {};
  const closureDate = state.commission?.selectedDate;
  if (!closureDate) return;

  const doNothingHttpResponses = [404];
  try {
    dispatch(loading(true));
    if (!officeId)
      throw new Error('Tried to fetchCreditorPaycheck() without state.impersonatedUser.officeId');
    if (!creditorId)
      throw new Error('Tried to fetchCreditorPaycheck() without state.impersonatedUser.creditorId');

    const paycheck = await getCreditorPaycheck({
      officeId,
      closureDate,
      externalCreditorId: creditorId,
    });

    dispatch(setPaycheckData(paycheck));
  } catch (error) {
    if (isAxiosError(error) && doNothingHttpResponses.includes(error?.response?.status!)) {
      dispatch(setPaycheckData(null));
      return;
    }
    handleDefaultErrors(error);
  } finally {
    dispatch(loading(false));
  }
};

export default reducer;
