import { useTheme } from '@material-ui/core';
import * as React from 'react';

import { iconBorderColor, iconFillColor } from 'theme';
import { DefaultIconType } from './types';

export const MonthlyReportIcon: React.FC<DefaultIconType> = ({
  active = false,
  color,
  height = 24,
  width = 24,
}) => {
  const { splitcPalette, palette } = useTheme();
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 20h1a1 1 0 0 1 0 2H3a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v17h2v-1a1 1 0 1 1 2 0v1h2v-2a1 1 0 0 1 2 0v2h2v-2a1 1 0 0 1 2 0v2h4Z"
        fill={color || iconBorderColor(splitcPalette, active)}
      />
      <path
        d="M15.8 4.1H9.2A2.2 2.2 0 0 0 7 6.3v5.5A2.2 2.2 0 0 0 9.2 14h6.6a2.2 2.2 0 0 0 2.2-2.2V6.3a2.2 2.2 0 0 0-2.2-2.2ZM10.3 3v2.2M14.7 3v2.2M7 7.4h11"
        stroke={iconFillColor(palette, splitcPalette, active)}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
