/* eslint-disable react-hooks/rules-of-hooks */
import { useMediaQuery } from '@material-ui/core';
import { DefaultTheme } from 'styled-components';

export const biggerThan = breakpoint => ({ theme }) => {
  return `@media (min-width: ${theme.breakpoints.values[breakpoint]}px)`;
};

export const isDesktop = () =>
  useMediaQuery<DefaultTheme>(theme => `(min-width:${theme.breakpoints.values.sm}px)`, {
    noSsr: true,
  });
